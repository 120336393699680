body.col-one.col-two.col-content #portal-column-content,
body.col-one.col-two.col-content article#portal-column-content {
  width: 100%;
}
@media (min-width: 992px) {
  body.col-one.col-two.col-content #portal-column-content,
  body.col-two.col-content #portal-column-content, 
  body.col-one.col-content #portal-column-content, 
  body.col-one.col-content article#portal-column-content, 
  body.col-one.col-two.col-content article#portal-column-content {
      width: 100%;
  }
}

.mt-search-ayt-container {
  color: #000;
}

#content div.controlPanel nav ul.configlets {
  height: auto;
  font-size: 0.5rem;

}

#content div.controlPanel a.btn {
    padding: 0.5rem;
    background-color: transparent;
}

#content .dataTables_wrapper .btn, 
#content .navbar .btn, 
#content ol.configlets a.btn, 
#content ol.configlets a.btn {

    padding: 0.3rem;
    color: #000;
    background-color: transparent;
    border: 1px solid #ddd;
}

input.form-check[type=checkbox]:empty,
.form-check-input input[type=checkbox]:empty,
.form-check-input input[type=checkbox],
div.form-check input[type=checkbox]:empty,
div.field input[type=checkbox]:empty  {
  height: 1rem;
  margin: 5px;
}

#content table.table tr,
#content table.table th.rotate,
#content table.table th.rotate div {
  vertical-align: baseline;
}

#content table th div {
  vertical-align: baseline;
}
