/* These are permission related selectors to hide/reveal for the following groups:
*  advertising_agencies, aligned_vendors, builder, colorcenter, edge, edge_preferred, edge_premier, edge_select, floorscapes, hardsurface_tms, independent_aligned, international, karastan, karastan_tms, marketing, mohawk_employees, nfa_smart_solutions, installers, omnify, openline, platinum, portico, properties, residential_sales_staff, retail_owners, signature, sister_companies, softsurface_tms, pergo, quickstep
*/

body .mtf_advertising_agencies, body .row .mtf_advertising_agencies,
body .mtf_aligned_vendors, body .row .mtf_aligned_vendors,
body .mtf_builder, body .row .mtf_builder,
body .mtf_colorcenter, body .row .mtf_colorcenter,
body .mtf_edge, body .row .mtf_edge,
body .mtf_edge_preferred, body .row .mtf_edge_preferred,
body .mtf_edge_premier, body .row .mtf_edge_premier,
body .mtf_edge_select, body .row .mtf_edge_select,
body .mtf_floorscapes, body .row .mtf_floorscapes,
body .mtf_hardsurface_tms, body .row .mtf_hardsurface_tms,
body .mtf_independent_aligned, body .row .mtf_independent_aligned,
body .mtf_international, body .row .mtf_international,
body .mtf_karastan, body .row .mtf_karastan,
body .mtf_karastan_tms, body .row .mtf_karastan_tms,
body .mtf_marketing, body .row .mtf_marketing,
body .mtf_mohawk_employees, body .row .mtf_mohawk_employees,
body .mtf_nfa_smart_solutions, body .row .mtf_nfa_smart_solutions,
body .mtf_installers, body .row .mtf_installers,
body .mtf_omnify, body .row .mtf_omnify,
body .mtf_openline, body .row .mtf_openline,
body .mtf_platinum, body .row .mtf_platinum,
body .mtf_portico, body .row .mtf_portico,
body .mtf_properties, body .row .mtf_properties,
body .mtf_residential_sales_staff, body .row .mtf_residential_sales_staff,
body .mtf_retail_owners, body .row .mtf_retail_owners,
body .mtf_signature, body .row .mtf_signature,
body .mtf_sister_companies, body .row .mtf_sister_companies,
body .mtf_softsurface_tms, body .row .mtf_softsurface_tms,
body .mtf_pergo, body .row .mtf_pergo,
body .mtf_quickstep, body .row .mtf_quickstep {
    display: none;
}

body.mceContentBody .mtf_advertising_agencies,
body.mceContentBody .mtf_aligned_vendors,
body.mceContentBody .mtf_builder,
body.mceContentBody .mtf_colorcenter,
body.mceContentBody .mtf_edge,
body.mceContentBody .mtf_edge_preferred,
body.mceContentBody .mtf_edge_premier,
body.mceContentBody .mtf_edge_select,
body.mceContentBody .mtf_floorscapes,
body.mceContentBody .mtf_hardsurface_tms,
body.mceContentBody .mtf_independent_aligned,
body.mceContentBody .mtf_international,
body.mceContentBody .mtf_karastan,
body.mceContentBody .mtf_karastan_tms,
body.mceContentBody .mtf_marketing,
body.mceContentBody .mtf_mohawk_employees,
body.mceContentBody .mtf_nfa_smart_solutions,
body.mceContentBody .mtf_installers,
body.mceContentBody .mtf_omnify,
body.mceContentBody .mtf_openline,
body.mceContentBody .mtf_platinum,
body.mceContentBody .mtf_portico,
body.mceContentBody .mtf_properties,
body.mceContentBody .mtf_residential_sales_staff,
body.mceContentBody .mtf_retail_owners,
body.mceContentBody .mtf_signature,
body.mceContentBody .mtf_sister_companies,
body.mceContentBody .mtf_softsurface_tms,
body.mceContentBody .mtf_pergo,
body.mceContentBody .mtf_quickstep {
    display: block;
}

body.mtf_advertising_agencies .mtf_advertising_agencies,
body.mtf_aligned_vendors .mtf_aligned_vendors,
body.mtf_builder .mtf_builder,
body.mtf_colorcenter .mtf_colorcenter,
body.mtf_edge .mtf_edge,
body.mtf_edge_preferred .mtf_edge_preferred,
body.mtf_edge_premier .mtf_edge_premier,
body.mtf_edge_select .mtf_edge_select,
body.mtf_floorscapes .mtf_floorscapes,
body.mtf_hardsurface_tms .mtf_hardsurface_tms,
body.mtf_independent_aligned .mtf_independent_aligned,
body.mtf_international .mtf_international,
body.mtf_karastan .mtf_karastan,
body.mtf_karastan_tms .mtf_karastan_tms,
body.mtf_marketing .mtf_marketing,
body.mtf_mohawk_employees .mtf_mohawk_employees,
body.mtf_nfa_smart_solutions .mtf_nfa_smart_solutions,
body.mtf_installers .mtf_installers,
body.mtf_omnify .mtf_omnify,
body.mtf_openline .mtf_openline,
body.mtf_platinum .mtf_platinum,
body.mtf_portico .mtf_portico,
body.mtf_properties .mtf_properties,
body.mtf_residential_sales_staff .mtf_residential_sales_staff,
body.mtf_retail_owners .mtf_retail_owners,
body.mtf_signature .mtf_signature,
body.mtf_sister_companies .mtf_sister_companies,
body.mtf_softsurface_tms .mtf_softsurface_tms,
body.mtf_pergo .mtf_pergo,
body.mtf_quickstep .mtf_quickstep {
    display: inline;
}

body.mtf_advertising_agencies div.mtf_advertising_agencies,
body.mtf_advertising_agencies li.mtf_advertising_agencies,
body.mtf_advertising_agencies p.mtf_advertising_agencies,
body.mtf_advertising_agencies span.mtf_advertising_agencies,
body.mtf_advertising_agencies ul.mtf_advertising_agencies,
body.mtf_aligned_vendors div.mtf_aligned_vendors,
body.mtf_aligned_vendors li.mtf_aligned_vendors,
body.mtf_aligned_vendors p.mtf_aligned_vendors,
body.mtf_aligned_vendors span.mtf_aligned_vendors,
body.mtf_aligned_vendors ul.mtf_aligned_vendors,
body.mtf_builder div.mtf_builder,
body.mtf_builder li.mtf_builder,
body.mtf_builder p.mtf_builder,
body.mtf_builder span.mtf_builder,
body.mtf_builder ul.mtf_builder,
body.mtf_colorcenter div.mtf_colorcenter,
body.mtf_colorcenter li.mtf_colorcenter,
body.mtf_colorcenter p.mtf_colorcenter,
body.mtf_colorcenter span.mtf_colorcenter,
body.mtf_colorcenter ul.mtf_colorcenter,
body.mtf_edge div.mtf_edge,
body.mtf_edge li.mtf_edge,
body.mtf_edge p.mtf_edge,
body.mtf_edge span.mtf_edge,
body.mtf_edge ul.mtf_edge,
body.mtf_edge_preferred div.mtf_edge_preferred,
body.mtf_edge_preferred li.mtf_edge_preferred,
body.mtf_edge_preferred p.mtf_edge_preferred,
body.mtf_edge_preferred span.mtf_edge_preferred,
body.mtf_edge_preferred ul.mtf_edge_preferred,
body.mtf_edge_premier div.mtf_edge_premier,
body.mtf_edge_premier li.mtf_edge_premier,
body.mtf_edge_premier p.mtf_edge_premier,
body.mtf_edge_premier span.mtf_edge_premier,
body.mtf_edge_premier ul.mtf_edge_premier,
body.mtf_edge_select div.mtf_edge_select,
body.mtf_edge_select li.mtf_edge_select,
body.mtf_edge_select p.mtf_edge_select,
body.mtf_edge_select span.mtf_edge_select,
body.mtf_edge_select ul.mtf_edge_select,
body.mtf_floorscapes div.mtf_floorscapes,
body.mtf_floorscapes li.mtf_floorscapes,
body.mtf_floorscapes p.mtf_floorscapes,
body.mtf_floorscapes span.mtf_floorscapes,
body.mtf_floorscapes ul.mtf_floorscapes,
body.mtf_hardsurface_tms div.mtf_hardsurface_tms,
body.mtf_hardsurface_tms li.mtf_hardsurface_tms,
body.mtf_hardsurface_tms p.mtf_hardsurface_tms,
body.mtf_hardsurface_tms span.mtf_hardsurface_tms,
body.mtf_hardsurface_tms ul.mtf_hardsurface_tms,
body.mtf_independent_aligned div.mtf_independent_aligned,
body.mtf_independent_aligned li.mtf_independent_aligned,
body.mtf_independent_aligned p.mtf_independent_aligned,
body.mtf_independent_aligned span.mtf_independent_aligned,
body.mtf_independent_aligned ul.mtf_independent_aligned,
body.mtf_international div.mtf_international,
body.mtf_international li.mtf_international,
body.mtf_international p.mtf_international,
body.mtf_international span.mtf_international,
body.mtf_international ul.mtf_international,
body.mtf_karastan div.mtf_karastan,
body.mtf_karastan li.mtf_karastan,
body.mtf_karastan p.mtf_karastan,
body.mtf_karastan span.mtf_karastan,
body.mtf_karastan ul.mtf_karastan,
body.mtf_karastan_tms div.mtf_karastan_tms,
body.mtf_karastan_tms li.mtf_karastan_tms,
body.mtf_karastan_tms p.mtf_karastan_tms,
body.mtf_karastan_tms span.mtf_karastan_tms,
body.mtf_karastan_tms ul.mtf_karastan_tms,
body.mtf_marketing div.mtf_marketing,
body.mtf_marketing li.mtf_marketing,
body.mtf_marketing p.mtf_marketing,
body.mtf_marketing span.mtf_marketing,
body.mtf_marketing ul.mtf_marketing,
body.mtf_mohawk_employees div.mtf_mohawk_employees,
body.mtf_mohawk_employees li.mtf_mohawk_employees,
body.mtf_mohawk_employees p.mtf_mohawk_employees,
body.mtf_mohawk_employees span.mtf_mohawk_employees,
body.mtf_mohawk_employees ul.mtf_mohawk_employees,
body.mtf_nfa_smart_solutions div.mtf_nfa_smart_solutions,
body.mtf_nfa_smart_solutions li.mtf_nfa_smart_solutions,
body.mtf_nfa_smart_solutions p.mtf_nfa_smart_solutions,
body.mtf_nfa_smart_solutions span.mtf_nfa_smart_solutions,
body.mtf_nfa_smart_solutions ul.mtf_nfa_smart_solutions,
body.mtf_installers div.mtf_installers,
body.mtf_installers li.mtf_installers,
body.mtf_installers p.mtf_installers,
body.mtf_installers span.mtf_installers,
body.mtf_installers ul.mtf_installers,
body.mtf_omnify div.mtf_omnify,
body.mtf_omnify li.mtf_omnify,
body.mtf_omnify p.mtf_omnify,
body.mtf_omnify span.mtf_omnify,
body.mtf_omnify ul.mtf_omnify,
body.mtf_openline div.mtf_openline,
body.mtf_openline li.mtf_openline,
body.mtf_openline p.mtf_openline,
body.mtf_openline span.mtf_openline,
body.mtf_openline ul.mtf_openline,
body.mtf_platinum div.mtf_platinum,
body.mtf_platinum li.mtf_platinum,
body.mtf_platinum p.mtf_platinum,
body.mtf_platinum span.mtf_platinum,
body.mtf_platinum ul.mtf_platinum,
body.mtf_portico div.mtf_portico,
body.mtf_portico li.mtf_portico,
body.mtf_portico p.mtf_portico,
body.mtf_portico span.mtf_portico,
body.mtf_portico ul.mtf_portico,
body.mtf_properties div.mtf_properties,
body.mtf_properties li.mtf_properties,
body.mtf_properties p.mtf_properties,
body.mtf_properties span.mtf_properties,
body.mtf_properties ul.mtf_properties,
body.mtf_residential_sales_staff div.mtf_residential_sales_staff,
body.mtf_residential_sales_staff li.mtf_residential_sales_staff,
body.mtf_residential_sales_staff p.mtf_residential_sales_staff,
body.mtf_residential_sales_staff span.mtf_residential_sales_staff,
body.mtf_residential_sales_staff ul.mtf_residential_sales_staff,
body.mtf_retail_owners div.mtf_retail_owners,
body.mtf_retail_owners li.mtf_retail_owners,
body.mtf_retail_owners p.mtf_retail_owners,
body.mtf_retail_owners span.mtf_retail_owners,
body.mtf_retail_owners ul.mtf_retail_owners,
body.mtf_signature div.mtf_signature,
body.mtf_signature li.mtf_signature,
body.mtf_signature p.mtf_signature,
body.mtf_signature span.mtf_signature,
body.mtf_signature ul.mtf_signature,
body.mtf_sister_companies div.mtf_sister_companies,
body.mtf_sister_companies li.mtf_sister_companies,
body.mtf_sister_companies p.mtf_sister_companies,
body.mtf_sister_companies span.mtf_sister_companies,
body.mtf_sister_companies ul.mtf_sister_companies,
body.mtf_softsurface_tms div.mtf_softsurface_tms,
body.mtf_softsurface_tms li.mtf_softsurface_tms,
body.mtf_softsurface_tms p.mtf_softsurface_tms,
body.mtf_softsurface_tms span.mtf_softsurface_tms,
body.mtf_softsurface_tms ul.mtf_softsurface_tms,
body.mtf_pergo div.mtf_pergo,
body.mtf_pergo li.mtf_pergo,
body.mtf_pergo p.mtf_pergo,
body.mtf_pergo span.mtf_pergo,
body.mtf_pergo ul.mtf_pergo,
body.mtf_quickstep div.mtf_quickstep,
body.mtf_quickstep li.mtf_quickstep,
body.mtf_quickstep p.mtf_quickstep,
body.mtf_quickstep span.mtf_quickstep,
body.mtf_quickstep ul.mtf_quickstep {
    display: block;
}

body.mtf_advertising_agencies table.mtf_advertising_agencies,
body.mtf_aligned_vendors table.mtf_aligned_vendors,
body.mtf_builder table.mtf_builder,
body.mtf_colorcenter table.mtf_colorcenter,
body.mtf_edge table.mtf_edge,
body.mtf_edge_preferred table.mtf_edge_preferred,
body.mtf_edge_premier table.mtf_edge_premier,
body.mtf_edge_select table.mtf_edge_select,
body.mtf_floorscapes table.mtf_floorscapes,
body.mtf_hardsurface_tms table.mtf_hardsurface_tms,
body.mtf_independent_aligned table.mtf_independent_aligned,
body.mtf_international table.mtf_international,
body.mtf_karastan table.mtf_karastan,
body.mtf_karastan_tms table.mtf_karastan_tms,
body.mtf_marketing table.mtf_marketing,
body.mtf_mohawk_employees table.mtf_mohawk_employees,
body.mtf_nfa_smart_solutions table.mtf_nfa_smart_solutions,
body.mtf_installers table.mtf_installers,
body.mtf_omnify table.mtf_omnify,
body.mtf_openline table.mtf_openline,
body.mtf_platinum table.mtf_platinum,
body.mtf_portico table.mtf_portico,
body.mtf_properties table.mtf_properties,
body.mtf_residential_sales_staff table.mtf_residential_sales_staff,
body.mtf_retail_owners table.mtf_retail_owners,
body.mtf_signature table.mtf_signature,
body.mtf_sister_companies table.mtf_sister_companies,
body.mtf_softsurface_tms table.mtf_softsurface_tms,
body.mtf_pergo table.mtf_pergo,
body.mtf_quickstep table.mtf_quickstep {
    display: table;
}

/* End permission related hide/reveal selectors */