.mt-search-ayt-container-outer {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: calc(100% + 1rem);
  background: white;
  border: 1px solid #ccc;
  text-align: left;
  width: 50vw;
  max-height: calc(100vh - 10rem);
  overflow: hidden;
  z-index: 50;
  letter-spacing: 0;
  border-radius: var(--mt-dialog-border-radius);
}

.mtt-mobile .mt-search-ayt-container-outer {
  position: absolute;
  left: 0;
  transform: unset;
}

.mt-search-ayt-container-outer-mobile {
  width: calc(100vw - var(--mt5-header-horizontal-padding) * 2);
}


.mt-search-ayt-container-outer-from-mobile-nav {
  top: calc(var(--mt-search-ayt-search-bar-bottom) + 1rem);
  left: unset;
  transform: unset;
  right: calc(-1 * var(--mtt-nav-container-mobile-width) + var(--mt5-header-horizontal-padding));
}

.mt-search-ayt-container {
  overflow: auto;
  max-height: calc(100vh - 10rem);
}

.mt-search-ayt-no-matches-container {
  font-size: 1rem;
  padding: 7.5px 20px;
}

.mt-search-ayt-no-matches-term {
  color: #5c5756;
  font-weight: bold;
}

.mt-search-ayt-result-title {
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  /* line-height: 1.5rem; */
}

/* .site-header a:link.mt-search-ayt-result-title:hover, .site-header a:link.mt-search-ayt-result-title:active,
.mt-search-ayt-result-title:hover, .mt-search-ayt-result-title:active {
  font-size: 1rem;
  text-decoration: none;
  color: #b9b7b7 !important;
} */

.mt-search-ayt-result-date {
  color: #666;
}

.mt-search-ayt-result {
  padding: 10.5px 21px;
  cursor: pointer;
}

.mt-search-ayt-container-mobile .mt-search-ayt-result,
.mt-search-ayt-container-mobile .mt-search-ayt-no-matches-container {
  padding: 7.5px 4px;
  line-height: 1.3rem;
}

.mt-search-ayt-result:hover, .mt-search-ayt-result:active {
  background: #fff5f4;
}

.mt-search-ayt-result-keyboard-highlight {
  background: #fff8f7;
}

.mt-search-ayt-result-keyboard-highlight.mt-search-ayt-result:hover,
.mt-search-ayt-result-keyboard-highlight.mt-search-ayt-result:active {
  background: #fff0ed;
}

.mt-search-ayt-show-all-container {
  padding: 7.5px 0 40px 0;
  text-align: center;
}

.mt-search-ayt-state-private,
.site-header a:link.mt-search-ayt-state-private,
.site-header a:link.mt-search-ayt-state-private:hover {
  color: #e0393e !important;
}
