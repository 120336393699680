@import url(https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap);
.mtt-nav-level-container-mobile {
  position: relative;
  overflow: hidden;
  transition: min-height .2s;
}

.mtt-footer .mtt-nav-level-container-mobile {
  overflow: hidden;
}

.mtt-nav-level-container-desktop {
  width: 25%;
  position: relative;
}

.mtt-nav-level {
  width: 100%;
  padding-left: 0;
  padding-bottom: 87px;
  /* for some reason this wasn't working when I tested it on iPhone 11, Safari 18.2 */
  /* it may be that it only accounts for menu bar and not for Safari nav bar */
  /* padding-bottom: env(safe-area-inset-bottom); */
  
}

.mtt-nav-level-in-collapse {
  padding-bottom: 0;
}

.mtt-nav-levels-container {
  position: relative;
}

.mtt-nlc-back-container {
  min-height: 1.33rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #CE0E2D;
  margin-bottom: 1.9rem;
}

.mtt-nlc-bc-arrow {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  margin-right: 1.11rem;
}

.mtt-nl-collapse-control {
  padding-top: 0.36rem;
  display: flex;
  align-items: center;
  grid-gap: 0.53rem;
  gap: 0.53rem;
  font-size: 1rem;
  margin-left: 1.5rem;
  cursor: pointer;
}

.mtt-nl-collapse-control img.mtt-nl-cc-arrow {
  margin: 0;
  height: 0.5rem;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}
.mtt-nl-collapse-control img.mtt-nl-cc-arrow-up {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.mtt-nl-item {
  font-size: 1.11rem;
  line-height: 1.35;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 1.34rem;
  cursor: pointer;
  transition: all 0.2s;
  --mt-nl-item-space-between-items: 2.44rem;
}
.mtt-nl-item + .mtt-nl-item {
  margin-top: var(--mt-nl-item-space-between-items);
}

.mtt-mobile .mtt-nl-item {
  margin-right: 0;
}

.mtt-nl-item img {
  margin-right: 0.25rem;
  margin-left: 0.5rem;
}

/* .mtt-nl-item img { */
  /* convert black to #4b4d4a */
  /* filter: invert(29%) sepia(6%) saturate(272%) hue-rotate(56deg) brightness(93%) contrast(86%); */
/* } */

.mtt-nl-item.mtt-nl-category {
  cursor: inherit;
  padding-bottom: 0.51rem;
  border-bottom: 1px solid white;
  margin-right: 2rem;
}

.mtt-nl-item.mtt-nl-item-hidden {
  display: none;
}

.mtt-nl-item.mtt-nl-item-none-selected, .mtt-nl-item.mtt-nl-item-selected, .mtt-nl-item.mtt-nl-category, .mtt-nl-item:hover,
.mtt-nl-item.mtt-nl-item-none-selected a, .mtt-nl-item.mtt-nl-item-selected a, .mtt-nl-item.mtt-nl-category a, .mtt-nl-item:hover a  {
  font-weight: bold;
  color: var(--mt-color-white);
}

.mtt-nl-item.private, .mtt-nl-item.private a {
  color: var(--mt-color-secondary-red);
}

.mtt-nl-item-in-collapse {
  margin-left: 1.29rem;
  margin-top: 1.42rem;
  margin-bottom: 1.08rem; 
}

.mtt-nl-item-in-collapse, .mtt-nl-item-with-collapse {
  margin-right: 0;
}

.mtt-nl-item-with-collapse {
  display: block;
  cursor: default;
}

.mtt-nl-item-with-collapse + .mtt-nl-item {
  margin-top: 1.44rem;
}
.mtt-nl-item-with-collapse-expanded + .mtt-nl-item {
  margin-top: var(--mt-nl-item-space-between-items);
}

.mtt-nl-show-links-item {
  /* color: var(--mt-color-secondary-grey); */
  color: var(--mt-color-secondary-grey-lighter);
  display: flex;
  min-height: 3.994rem;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
}


.mtt-nl-show-links-item:hover  {
  color: var(--mt-color-white);
}


.mt-search-ayt-container-outer {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  top: calc(100% + 1rem);
  background: white;
  border: 1px solid #ccc;
  text-align: left;
  width: 50vw;
  max-height: calc(100vh - 10rem);
  overflow: hidden;
  z-index: 50;
  letter-spacing: 0;
  border-radius: var(--mt-dialog-border-radius);
}

.mtt-mobile .mt-search-ayt-container-outer {
  position: absolute;
  left: 0;
  -webkit-transform: unset;
          transform: unset;
}

.mt-search-ayt-container-outer-mobile {
  width: calc(100vw - var(--mt5-header-horizontal-padding) * 2);
}


.mt-search-ayt-container-outer-from-mobile-nav {
  top: calc(var(--mt-search-ayt-search-bar-bottom) + 1rem);
  left: unset;
  -webkit-transform: unset;
          transform: unset;
  right: calc(-1 * var(--mtt-nav-container-mobile-width) + var(--mt5-header-horizontal-padding));
}

.mt-search-ayt-container {
  overflow: auto;
  max-height: calc(100vh - 10rem);
}

.mt-search-ayt-no-matches-container {
  font-size: 1rem;
  padding: 7.5px 20px;
}

.mt-search-ayt-no-matches-term {
  color: #5c5756;
  font-weight: bold;
}

.mt-search-ayt-result-title {
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  /* line-height: 1.5rem; */
}

/* .site-header a:link.mt-search-ayt-result-title:hover, .site-header a:link.mt-search-ayt-result-title:active,
.mt-search-ayt-result-title:hover, .mt-search-ayt-result-title:active {
  font-size: 1rem;
  text-decoration: none;
  color: #b9b7b7 !important;
} */

.mt-search-ayt-result-date {
  color: #666;
}

.mt-search-ayt-result {
  padding: 10.5px 21px;
  cursor: pointer;
}

.mt-search-ayt-container-mobile .mt-search-ayt-result,
.mt-search-ayt-container-mobile .mt-search-ayt-no-matches-container {
  padding: 7.5px 4px;
  line-height: 1.3rem;
}

.mt-search-ayt-result:hover, .mt-search-ayt-result:active {
  background: #fff5f4;
}

.mt-search-ayt-result-keyboard-highlight {
  background: #fff8f7;
}

.mt-search-ayt-result-keyboard-highlight.mt-search-ayt-result:hover,
.mt-search-ayt-result-keyboard-highlight.mt-search-ayt-result:active {
  background: #fff0ed;
}

.mt-search-ayt-show-all-container {
  padding: 7.5px 0 40px 0;
  text-align: center;
}

.mt-search-ayt-state-private,
.site-header a:link.mt-search-ayt-state-private,
.site-header a:link.mt-search-ayt-state-private:hover {
  color: #e0393e !important;
}

.mtt-header-actions-and-status-bar {
  height: 6rem;
  padding: 0 var(--mt5-header-horizontal-padding);
  /* padding-block: 0;
  padding-left: max(env(safe-area-inset-left), var(--mt5-header-horizontal-padding));
  padding-right: max(env(safe-area-inset-right), var(--mt5-header-horizontal-padding)); */
  background-color: var(--mt-color-primary-black);
  display: flex;
  align-items: center;
  color: var(--mt-color-white);
  --mtt-hasb-desktop-search-bar-width: max(calc(29.11 * var(--mt-container-width-unit)), 31rem);
}

.mtt-mobile .mtt-header-actions-and-status-bar {
  --mtt-hasb-mobile-flex-gap: 0.76rem;
  height: 7.39rem;
  align-items: flex-end;
  grid-gap: var(--mtt-hasb-mobile-flex-gap);
  gap: var(--mtt-hasb-mobile-flex-gap);
  padding-top: 0.76rem;
  padding-bottom: 0.83rem;
  flex-wrap: wrap;
}

.mtt-mobile .mtt-header-actions-and-status-bar a.button.button-edge-icon {
  min-width: 4.94rem;
  min-height: 2.22rem;
  font-size: var(--mt-text-size-small);
  margin-top: auto;
  margin-bottom: 0.1rem;
  padding: 0.399rem;
}

.mtt-hasb-divider {
  margin: 0 calc(1.25 * var(--mt-container-width-unit));
  width: 0;
  height: 0.833rem;
  border-right: 1px solid var(--mt-color-white);
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
}

.mtt-hasb-initials-container {
  background: var(--mt-color-primary-red);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.55rem;
  width: 2.61rem;
  border-radius: 0.67rem;
  margin-right: 0.6rem;
  margin-left: calc(4.42 * var(--mt-container-width-unit));
  flex-shrink: 0;
}

.mtt-mobile .mtt-hasb-initials-container {
  margin-left: auto;
  margin-top: auto;
  margin-bottom: 0.1rem;
  margin-right: 0;
  height: 2.22rem;
}

.mtt-hasb-edge-status-icon {
  height: 1.444rem;
}
.mtt-mobile .mtt-hasb-edge-status-icon {
  height: 1.18rem;
}

.mtt-mobile .mtt-hasb-edge-status-icon {
  margin-left: auto;
}

.mtt-hasb-greeting {
  margin-right: calc(1.875 * var(--mt-container-width-unit));
}
.mtt-mobile .mtt-hasb-greeting {
  font-size: var(--mt-text-size-extra-small);
  line-height: var(--mt-text-size-extra-small-line-height);
}

.mtt-hasb-user-actions-container {
  position: relative;
  padding: 0.5rem 1rem;
  margin-right: -1rem;
  cursor: pointer;
  flex-shrink: 0;
}

.mtt-mobile .mtt-hasb-user-actions-container {
  padding: 0.15rem 1rem 0.25rem 1rem;
  z-index: 51;
}

.mtt-hasb-user-actions {
  position: absolute;
  display: none;
  top: 100%;
  left: 0;
  white-space: nowrap;
  padding: 1.61rem 3rem 1.55rem 3rem; 
  background: var(--mt-color-primary-black);
  z-index: 40;
}

.mtt-mobile .mtt-hasb-user-actions {
  position: absolute;
  top: 100%;
  left: unset;
  right: -6rem;
}

.mtt-hasb-user-actions a {
  color: var(--mt-color-white) !important;
  font-weight: bold;
}
.mtt-hasb-user-actions li + li{
  margin-top: 1.05rem;
}

.mtt-hasb-user-actions ul {
  padding-left: 0;
}

.mtt-hasb-user-actions-container:hover .mtt-hasb-user-actions, .mtt-hasb-user-actions.mtt-hasb-user-actions-open {
  display: block;
}

.mtt-hasb-actions-group {
  display: flex;
  align-items: center;
}

.mtt-hasb-actions-group .button + .button {
  margin-left: 1rem;
}

.mtt-hasb-logo {
  height: 3.87rem;
  margin-right: calc(1.875 * var(--mt-container-width-unit));
}

.mtt-mobile .mtt-hasb-logo {
  height: 2.59rem;
}

.mtt-hasb-menu-icon {
  height: 1.444rem;
}

.mtt-mobile .mtt-hasb-menu-icon {
  height: 1rem;
}

.mtt-hasb-menu-icon-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 1.5rem;
  margin: -1.5rem;
}

.mtt-mobile .mtt-hasb-menu-icon-container {
  padding: 0.5rem;
  margin: -0.5rem;
}

.mtt-hasb-right-side-container {
  min-width: calc(50% - var(--mtt-hasb-desktop-search-bar-width) / 2);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.mtt-mobile .mtt-hasb-right-side-container {
  grid-gap: var(--mtt-hasb-mobile-flex-gap);
  gap: var(--mtt-hasb-mobile-flex-gap);
  margin-left: auto;
}

.mtt-hasb-search-and-nav-container-mobile {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
}

.mtt-hasb-search-bar {
  background-color: var(--mt-color-white);
  border-radius: var(--mt-button-border-radius);
  width: var(--mtt-hasb-desktop-search-bar-width);
  flex-shrink: 1;
  height: var(--mt-small-button-height);
  position: relative;
  padding-left: 1rem;
  padding-right: 3.5rem;
  display: flex;
  align-items: center;
  margin-left: auto;
}

@media (max-width: 1420px) {
  .mtt-header-actions-and-status-bar {
    --mtt-hasb-desktop-search-bar-width: calc(39.29 * var(--mt-container-width-unit));
  }
}

.mtt-mobile .mtt-hasb-search-bar {
  width: 100%;
  margin-right: 1.39rem;
  height: 2.44rem;
  font-size: 1rem;
}

.mtt-hasb-search-bar-icon {
  position: absolute;
  top: 50%;
  right: 1rem;
  height: 0.769rem;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.mtt-hasb-search-bar-icon-clickable {
  cursor: pointer;
}

.mtt-hasb-search-close-icon {
  position: absolute;
  top: 50%;
  right: 2.769rem;
  height: 0.769rem;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  cursor: pointer;
}

input[type="text"].mtt-hasb-search-input, input[type="text"].mtt-hasb-search-input:focus {
  width: 100%;
  border: none;
  padding: 0;
  font-size: 1rem;
  line-height: 1.4;
  letter-spacing: 0;
  color: var(--mt-color-primary-black);  
}

input[type="text"].mtt-hasb-search-input::-webkit-input-placeholder {
  color: var(--mt-color-disabled-grey);
}

input[type="text"].mtt-hasb-search-input::placeholder {
  color: var(--mt-color-disabled-grey);
}
input[type="text"].mtt-hasb-search-input:placeholder-shown {
  text-overflow: ellipsis;
}

input[type="text"].mtt-hasb-search-input::-ms-clear {
  display: none;
  height: 0;
  width: 0;
}

.mtt-hasb-search-form {
  width: 100%;
}
body.mtt-header-mobile-nav-body-slide {
  right: var(--mtt-nav-container-mobile-width);
  transition: right 0.3s ease;
  /* transition: right 0.3s 0.075s ease; */
  transition-delay: 0;
  /* transform: translateX(calc(-1 * (var(--mtt-nav-container-mobile-width)))); */
}

.mtt-header-mobile-nav-backdrop {
  position: fixed;
  /* we make backdrop slightly wider than viewport to compensate for iOS safari rendering bug 
     that was creating a narrow sliver not covered by the backdrop on the left in portrait mode  */
  width: 101vw;
  top: 0;
  left: -1vw;
  right: 0;
  bottom: 0;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 39;
}
.mtt-header-mobile-nav-backdrop-open {
  overflow: hidden;
  background-color: #000000D9;
  visibility: visible;
  pointer-events: auto;
  opacity: 1;
}


.mtt-header-mobile-nav-container {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    /* overflow-y: scroll prevents the menu from shifting, and sometimes switching back to desktop view, 
       when position: fixed on the body removes the scrollbar */
    overflow-y: scroll;
    color: white;
    width: var(--mtt-nav-container-mobile-width);
    height: 100vh;
    background: var(--mt-color-primary-black);
    padding-block: 1.16rem;
    padding-left: 1.33rem;
    padding-right: 0.89rem;
    -webkit-transform: translateX(var(--mtt-nav-container-mobile-width));
            transform: translateX(var(--mtt-nav-container-mobile-width));
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
    z-index: 40;
}
.mtt-header-mobile-nav-container-open {
    -webkit-transform: translateX(0);
            transform: translateX(0);
}

.mtt-hmnc-actions-container {
  display: flex;
  grid-gap: 0.61rem;
  gap: 0.61rem;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 8.88rem;
  /* padding-right: env(safe-area-inset-right); */
}

.mtt-hmnc-search-bar {
  width: 100%;
  height: 2.444rem;
  font-size: 1rem;
  background-color: var(--mt-color-white);
  border-radius: var(--mt-button-border-radius);
  flex-shrink: 1;
  position: relative;
  padding-left: 1rem;
  padding-right: 3.5rem;
  display: flex;
  align-items: center;
}

.mtt-hmnc-button.button.button-small {
  min-height: 2.444rem;
  padding-block: 0.67rem;
  padding-inline: 0.86rem;
  flex-shrink: 0;
} 

.mtt-hmnc-close-x {
  position: absolute;
  color: white;
  font-weight: bold;
  font-size: 2.06rem;
  line-height: 1.35;
  right: 2.94rem;
  top: calc(2.33rem + var(--mtt-hmnc-close-x-scroll-offset-top));
  z-index: 50;
}
.mtt-hmnc-close-x:hover {
  color: white;
}


.mtt-hmnc-status-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 1.6rem;
  /* padding-right: env(safe-area-inset-right); */
}

.mtt-hmnc-sc-account-code {
  font-size: 0.89rem;
  line-height: 1.29;
  padding-right: 0.5rem;
}

.mtt-hmnc-sc-edge-status-icon {
  height: 1.6rem;
}


/* .mtt-nav-container-mobile {
  position: fixed;
  right: -100%;
  top: 0;
  bottom: 0;
  overflow-y: scroll;
  width: var(--mtt-nav-container-mobile-width);
  height: 100vh;
  padding-left: 1.61rem;
  padding-right: 1.29rem;
  background: var(--mt-color-primary-black);
  color: var(--mt-color-secondary-grey-lighter);
  transition: right 0.3s ease;
  z-index: 40;
} */
.mtt-header-nav-bar {
  display: flex;
  padding: 0 var(--mt5-header-horizontal-padding);
  justify-content: center;
  align-items: center;
  height: 3.22rem;
  position: relative;
  background-color: var(--mt-color-primary-red);
}

/* .mtt-mobile .mtt-header-nav-bar {
  padding-right: 4.61vw;
  height: 6.67rem;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
} */

.mtt-mobile .mtt-header-nav-bar {
  flex-wrap: wrap;
}

.mtt-hnb-nav-items {
  display: flex;
  justify-content: space-between;
  color: white;
  font-size: 1.11rem;
  font-weight: bold;
  position: relative;
  padding-right: 1.89rem;
}


.mtt-hnb-nav-item {
  padding-inline: 1.44rem;
  z-index: 30;
  position: relative;
  cursor: pointer;
}
.mtt-hnb-nav-item-is-selected {
  color: black;
}
.mtt-hnb-nav-item:before {
  opacity: 0;
  content: " ";
  width: 100%;
  top: -0.88rem;
  left: 0;
  position: absolute;
  border-radius: 0.56rem 0.56rem 0 0;
  z-index: -1;
  height: 4rem;
  background-color: white;
}
.mtt-hnb-nav-item-is-selected:before {
  transition: opacity 0.3s ease;
  opacity: 1;
}

.mtt-hnb-desktop-nav {
  position: absolute;
  /* top: calc(100% + 1.28rem); */
  top: calc(100% + 0.45rem);
  left: 0;
  right: 0;
  width: calc(100% - 0.9rem);
  max-width: 95.88rem;
  margin-inline: auto;
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 0 0 0.56rem 0.56rem;
  padding-top: 1.67rem;
  padding-bottom: 0.22rem;
  padding-right: 3.19rem;
  padding-left: 2rem;
  color: black;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap:3.6rem;
  gap:3.6rem;
}

@media (min-width: 88rem) {
  .mtt-hnb-desktop-nav {
    padding-left: 3.19rem;
    grid-gap: min(6.39rem, calc(5.68 * var(--mt-container-width-unit)));
    gap: min(6.39rem, calc(5.68 * var(--mt-container-width-unit)));
  }
}



.mtt-hnb-dn-second-level-item {
  padding-bottom: 0.444rem;
}

/* .mtt-hnb-second-level-item-admin-link a {
  color: var(--mt-color-secondary-grey-lighter);
} */
/* Remove this if we decide to go with grey color for admin link just above */
.mtt-hnb-second-level-item-admin-link a, .mtt-hnb-second-level-item-admin-link a:hover {
  color: var(--mtt-private-item-color) !important;
}

.mtt-hnb-dn-second-level-item h3 {
  font-weight: bold;
  text-transform: none;
  font-size: 1.11rem;
  line-height: 1.2;
  color: #CE0E2D;
}
.mtt-hnb-dn-second-level-item h3 a {
  color: #CE0E2D;
}
.mtt-hnb-dn-second-level-item-has-children h3 {
  border-bottom: 1px solid #707070;
  margin-bottom: 0.528rem;
  line-height: 1.35;
}
.mtt-hnb-second-level-item-admin-link h3 {
  border-bottom: none;
}
.mtt-hnb-dn-second-level-item-has-children, .mtt-hnb-dn-second-level-item:last-of-type  {
  padding-bottom: 2.7rem;
}

.mtt-hnb-dn-third-level-items {
  -webkit-column-count: 2;
          column-count: 2;
  grid-column-gap: 2.22rem;
  -webkit-column-gap: 2.22rem;
          column-gap: 2.22rem;
  font-weight: normal;
}
.mtt-hnb-dn-third-level-items-wide {
  -webkit-column-count: 2;
          column-count: 2;
  -webkit-column-fill: balance;
          column-fill: balance;
  grid-column-gap: 1.68rem;
  -webkit-column-gap: 1.68rem;
          column-gap: 1.68rem;
}

.mtt-hnb-dn-tli-item {
  font-size: 0.89rem;
  line-height: 1.375;
  page-break-inside: avoid;
  -webkit-column-break-inside: avoid;
          break-inside: avoid;
}


.mtt-hnb-dn-tli-item a:hover, .mtt-hnb-dn-tli-item a:focus, .mtt-hnb-dn-tli-item a:active {
  font-weight: normal;
}
.mtt-hnb-dn-tli-item-spacer {
  height: 0.444rem;
}

.mtt-hnb-nav-item.private, .mtt-hnb-dn-second-level-item.private a, .mtt-hnb-dn-tli-item.private a{
  color: var(--mtt-private-item-color);
}


.mtt-footer {
  padding: 2.37rem var(--mt-footer-horizontal-padding) 0 var(--mt-footer-horizontal-padding);
}
.mtt-footer.mtt-mobile {
  padding: 1.78rem 1.78rem 0 1.78rem;
}
.mtt-footer-contact {
  padding-top: 1.36rem;
  font-weight: bold;
}
.mtt-footer-contact a, .mtt-footer-contact a:hover {
  color: white;
}

.mtt-footer-follow-container img {
  max-height: 1.8rem;
  max-width: auto;
  margin-top: 0.8rem;
}
.mtt-mobile .mtt-footer-follow-container img {
  max-height: 1.65rem;
}
.mtt-footer-follow-container a + a {
  margin-left: 1rem;
}
.mtt-mobile .mtt-footer-follow-container a + a {
  margin-left: 1rem;
}
.mtt-ffc-link-smaller img {
  max-height: 1.5rem;
  vertical-align: top;
  -webkit-transform: translateY(0.15rem);
          transform: translateY(0.15rem);
}
.mtt-mobile .mtt-ffc-link-smaller img {
  max-height: 1.375rem;
  -webkit-transform: translateY(0.1375rem);
          transform: translateY(0.1375rem);
}

.mtt-footer-legal {
  text-align: center;
  padding: 3.67rem 0;
  font-weight: bold;
  /* color: var(--mt-color-secondary-grey); */
  color: var(--mt-color-secondary-grey-lighter);
}

.mtt-mobile .mtt-footer-legal {
  padding: 3.16rem 0 2.3rem 0;
}

.mtt-footer-logo-container {
  padding: 0 var(--mt-footer-horizontal-padding-inner) 2.67rem var(--mt-footer-horizontal-padding-inner);
}
.mtt-mobile .mtt-footer-logo-container {
  padding: 0 0 1.78rem 0;
  /* border-bottom: 1px solid var(--mt-color-secondary-grey); */
  border-bottom: 1px solid var(--mt-color-secondary-grey-lighter);
}
.mtt-footer-logo-container img {
  height: 3.87rem;
}
.mtt-mobile .mtt-footer-logo-container img {
  height: 1.69rem;
}

.mtt-footer-more-info-container {
  padding: 2.88rem var(--mt-footer-horizontal-padding-inner) 0 var(--mt-footer-horizontal-padding-inner);
  /* border-top: 1px solid var(--mt-color-secondary-grey); */
  border-top: 1px solid var(--mt-color-secondary-grey-lighter);
}

.mtt-mobile .mtt-footer-more-info-container {
  padding: 1.94rem 0 0 0;
}

.mtt-footer-nav-container-mobile {
  padding: 1rem 0 2.44rem 0;
  overflow: hidden;
}

.mtt-footer-nav-desktop {
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* this hides the left border on categories that have wrapped to new row */
.mtt-footer-nav-desktop:after {
  content: '';
  position: absolute;
  border-left: 1px solid black;
  z-index: 1;
  left: 0;
  top: 0;
  height: 100%;
  width: 0;
}

.mtt-fnd-category-container {
  padding: 1.25rem var(--mt-footer-horizontal-padding-inner) var(--mt-footer-horizontal-padding-inner) var(--mt-footer-horizontal-padding-inner);
  border-bottom: 1px solid var(--mt-color-secondary-grey);
  margin-bottom: -1px;
  flex-grow: 1;
}
.mtt-fnd-category-container + .mtt-fnd-category-container {
  border-left: 1px solid var(--mt-color-secondary-grey);
}

.mtt-fnd-category-title, .mtt-fnd-desktop-item {
  display: flex;
  align-items: center;
  min-height: 2.78rem;
}
.mtt-fnd-category-title.private, .mtt-fnd-desktop-item.private, .mtt-fnd-desktop-item.private a, .mtt-fnd-desktop-item.private a:hover {
  /* color: var(--mt-color-secondary-red); */
  color: var(--mtt-private-item-color);
}

.mtt-fnd-desktop-item, .mtt-fnd-desktop-item a, .mtt-fnd-desktop-item a:hover {
  cursor: pointer;
  font-weight: bold;
  color: var(--mt-color-white);
}

.mtt-fnd-desktop-item-hidden {
  display: none;
}

.mtt-fnd-level {
  padding-bottom: 0.75rem;
}

.mtt-fnd-level-2 .mtt-fnd-desktop-item {
  opacity: 0.7;
}
.mtt-fnd-level-3 .mtt-fnd-desktop-item {
  opacity: 0.4;
}

.mtt-footer-split-info-container {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 540px) {
  .mtt-footer-split-info-container {
    display: block;
  }
  .mtt-footer-follow-container {
    margin-top: 1.8rem;
  }
}


/* These are permission related selectors to hide/reveal for the following groups:
*  advertising_agencies, aligned_vendors, builder, colorcenter, edge, edge_preferred, edge_premier, edge_select, floorscapes, hardsurface_tms, independent_aligned, international, karastan, karastan_tms, marketing, mohawk_employees, nfa_smart_solutions, installers, omnify, openline, platinum, portico, properties, residential_sales_staff, retail_owners, signature, sister_companies, softsurface_tms, pergo, quickstep
*/

body .mtf_advertising_agencies, body .row .mtf_advertising_agencies,
body .mtf_aligned_vendors, body .row .mtf_aligned_vendors,
body .mtf_builder, body .row .mtf_builder,
body .mtf_colorcenter, body .row .mtf_colorcenter,
body .mtf_edge, body .row .mtf_edge,
body .mtf_edge_preferred, body .row .mtf_edge_preferred,
body .mtf_edge_premier, body .row .mtf_edge_premier,
body .mtf_edge_select, body .row .mtf_edge_select,
body .mtf_floorscapes, body .row .mtf_floorscapes,
body .mtf_hardsurface_tms, body .row .mtf_hardsurface_tms,
body .mtf_independent_aligned, body .row .mtf_independent_aligned,
body .mtf_international, body .row .mtf_international,
body .mtf_karastan, body .row .mtf_karastan,
body .mtf_karastan_tms, body .row .mtf_karastan_tms,
body .mtf_marketing, body .row .mtf_marketing,
body .mtf_mohawk_employees, body .row .mtf_mohawk_employees,
body .mtf_nfa_smart_solutions, body .row .mtf_nfa_smart_solutions,
body .mtf_installers, body .row .mtf_installers,
body .mtf_omnify, body .row .mtf_omnify,
body .mtf_openline, body .row .mtf_openline,
body .mtf_platinum, body .row .mtf_platinum,
body .mtf_portico, body .row .mtf_portico,
body .mtf_properties, body .row .mtf_properties,
body .mtf_residential_sales_staff, body .row .mtf_residential_sales_staff,
body .mtf_retail_owners, body .row .mtf_retail_owners,
body .mtf_signature, body .row .mtf_signature,
body .mtf_sister_companies, body .row .mtf_sister_companies,
body .mtf_softsurface_tms, body .row .mtf_softsurface_tms,
body .mtf_pergo, body .row .mtf_pergo,
body .mtf_quickstep, body .row .mtf_quickstep {
    display: none;
}

body.mceContentBody .mtf_advertising_agencies,
body.mceContentBody .mtf_aligned_vendors,
body.mceContentBody .mtf_builder,
body.mceContentBody .mtf_colorcenter,
body.mceContentBody .mtf_edge,
body.mceContentBody .mtf_edge_preferred,
body.mceContentBody .mtf_edge_premier,
body.mceContentBody .mtf_edge_select,
body.mceContentBody .mtf_floorscapes,
body.mceContentBody .mtf_hardsurface_tms,
body.mceContentBody .mtf_independent_aligned,
body.mceContentBody .mtf_international,
body.mceContentBody .mtf_karastan,
body.mceContentBody .mtf_karastan_tms,
body.mceContentBody .mtf_marketing,
body.mceContentBody .mtf_mohawk_employees,
body.mceContentBody .mtf_nfa_smart_solutions,
body.mceContentBody .mtf_installers,
body.mceContentBody .mtf_omnify,
body.mceContentBody .mtf_openline,
body.mceContentBody .mtf_platinum,
body.mceContentBody .mtf_portico,
body.mceContentBody .mtf_properties,
body.mceContentBody .mtf_residential_sales_staff,
body.mceContentBody .mtf_retail_owners,
body.mceContentBody .mtf_signature,
body.mceContentBody .mtf_sister_companies,
body.mceContentBody .mtf_softsurface_tms,
body.mceContentBody .mtf_pergo,
body.mceContentBody .mtf_quickstep {
    display: block;
}

body.mtf_advertising_agencies .mtf_advertising_agencies,
body.mtf_aligned_vendors .mtf_aligned_vendors,
body.mtf_builder .mtf_builder,
body.mtf_colorcenter .mtf_colorcenter,
body.mtf_edge .mtf_edge,
body.mtf_edge_preferred .mtf_edge_preferred,
body.mtf_edge_premier .mtf_edge_premier,
body.mtf_edge_select .mtf_edge_select,
body.mtf_floorscapes .mtf_floorscapes,
body.mtf_hardsurface_tms .mtf_hardsurface_tms,
body.mtf_independent_aligned .mtf_independent_aligned,
body.mtf_international .mtf_international,
body.mtf_karastan .mtf_karastan,
body.mtf_karastan_tms .mtf_karastan_tms,
body.mtf_marketing .mtf_marketing,
body.mtf_mohawk_employees .mtf_mohawk_employees,
body.mtf_nfa_smart_solutions .mtf_nfa_smart_solutions,
body.mtf_installers .mtf_installers,
body.mtf_omnify .mtf_omnify,
body.mtf_openline .mtf_openline,
body.mtf_platinum .mtf_platinum,
body.mtf_portico .mtf_portico,
body.mtf_properties .mtf_properties,
body.mtf_residential_sales_staff .mtf_residential_sales_staff,
body.mtf_retail_owners .mtf_retail_owners,
body.mtf_signature .mtf_signature,
body.mtf_sister_companies .mtf_sister_companies,
body.mtf_softsurface_tms .mtf_softsurface_tms,
body.mtf_pergo .mtf_pergo,
body.mtf_quickstep .mtf_quickstep {
    display: inline;
}

body.mtf_advertising_agencies div.mtf_advertising_agencies,
body.mtf_advertising_agencies li.mtf_advertising_agencies,
body.mtf_advertising_agencies p.mtf_advertising_agencies,
body.mtf_advertising_agencies span.mtf_advertising_agencies,
body.mtf_advertising_agencies ul.mtf_advertising_agencies,
body.mtf_aligned_vendors div.mtf_aligned_vendors,
body.mtf_aligned_vendors li.mtf_aligned_vendors,
body.mtf_aligned_vendors p.mtf_aligned_vendors,
body.mtf_aligned_vendors span.mtf_aligned_vendors,
body.mtf_aligned_vendors ul.mtf_aligned_vendors,
body.mtf_builder div.mtf_builder,
body.mtf_builder li.mtf_builder,
body.mtf_builder p.mtf_builder,
body.mtf_builder span.mtf_builder,
body.mtf_builder ul.mtf_builder,
body.mtf_colorcenter div.mtf_colorcenter,
body.mtf_colorcenter li.mtf_colorcenter,
body.mtf_colorcenter p.mtf_colorcenter,
body.mtf_colorcenter span.mtf_colorcenter,
body.mtf_colorcenter ul.mtf_colorcenter,
body.mtf_edge div.mtf_edge,
body.mtf_edge li.mtf_edge,
body.mtf_edge p.mtf_edge,
body.mtf_edge span.mtf_edge,
body.mtf_edge ul.mtf_edge,
body.mtf_edge_preferred div.mtf_edge_preferred,
body.mtf_edge_preferred li.mtf_edge_preferred,
body.mtf_edge_preferred p.mtf_edge_preferred,
body.mtf_edge_preferred span.mtf_edge_preferred,
body.mtf_edge_preferred ul.mtf_edge_preferred,
body.mtf_edge_premier div.mtf_edge_premier,
body.mtf_edge_premier li.mtf_edge_premier,
body.mtf_edge_premier p.mtf_edge_premier,
body.mtf_edge_premier span.mtf_edge_premier,
body.mtf_edge_premier ul.mtf_edge_premier,
body.mtf_edge_select div.mtf_edge_select,
body.mtf_edge_select li.mtf_edge_select,
body.mtf_edge_select p.mtf_edge_select,
body.mtf_edge_select span.mtf_edge_select,
body.mtf_edge_select ul.mtf_edge_select,
body.mtf_floorscapes div.mtf_floorscapes,
body.mtf_floorscapes li.mtf_floorscapes,
body.mtf_floorscapes p.mtf_floorscapes,
body.mtf_floorscapes span.mtf_floorscapes,
body.mtf_floorscapes ul.mtf_floorscapes,
body.mtf_hardsurface_tms div.mtf_hardsurface_tms,
body.mtf_hardsurface_tms li.mtf_hardsurface_tms,
body.mtf_hardsurface_tms p.mtf_hardsurface_tms,
body.mtf_hardsurface_tms span.mtf_hardsurface_tms,
body.mtf_hardsurface_tms ul.mtf_hardsurface_tms,
body.mtf_independent_aligned div.mtf_independent_aligned,
body.mtf_independent_aligned li.mtf_independent_aligned,
body.mtf_independent_aligned p.mtf_independent_aligned,
body.mtf_independent_aligned span.mtf_independent_aligned,
body.mtf_independent_aligned ul.mtf_independent_aligned,
body.mtf_international div.mtf_international,
body.mtf_international li.mtf_international,
body.mtf_international p.mtf_international,
body.mtf_international span.mtf_international,
body.mtf_international ul.mtf_international,
body.mtf_karastan div.mtf_karastan,
body.mtf_karastan li.mtf_karastan,
body.mtf_karastan p.mtf_karastan,
body.mtf_karastan span.mtf_karastan,
body.mtf_karastan ul.mtf_karastan,
body.mtf_karastan_tms div.mtf_karastan_tms,
body.mtf_karastan_tms li.mtf_karastan_tms,
body.mtf_karastan_tms p.mtf_karastan_tms,
body.mtf_karastan_tms span.mtf_karastan_tms,
body.mtf_karastan_tms ul.mtf_karastan_tms,
body.mtf_marketing div.mtf_marketing,
body.mtf_marketing li.mtf_marketing,
body.mtf_marketing p.mtf_marketing,
body.mtf_marketing span.mtf_marketing,
body.mtf_marketing ul.mtf_marketing,
body.mtf_mohawk_employees div.mtf_mohawk_employees,
body.mtf_mohawk_employees li.mtf_mohawk_employees,
body.mtf_mohawk_employees p.mtf_mohawk_employees,
body.mtf_mohawk_employees span.mtf_mohawk_employees,
body.mtf_mohawk_employees ul.mtf_mohawk_employees,
body.mtf_nfa_smart_solutions div.mtf_nfa_smart_solutions,
body.mtf_nfa_smart_solutions li.mtf_nfa_smart_solutions,
body.mtf_nfa_smart_solutions p.mtf_nfa_smart_solutions,
body.mtf_nfa_smart_solutions span.mtf_nfa_smart_solutions,
body.mtf_nfa_smart_solutions ul.mtf_nfa_smart_solutions,
body.mtf_installers div.mtf_installers,
body.mtf_installers li.mtf_installers,
body.mtf_installers p.mtf_installers,
body.mtf_installers span.mtf_installers,
body.mtf_installers ul.mtf_installers,
body.mtf_omnify div.mtf_omnify,
body.mtf_omnify li.mtf_omnify,
body.mtf_omnify p.mtf_omnify,
body.mtf_omnify span.mtf_omnify,
body.mtf_omnify ul.mtf_omnify,
body.mtf_openline div.mtf_openline,
body.mtf_openline li.mtf_openline,
body.mtf_openline p.mtf_openline,
body.mtf_openline span.mtf_openline,
body.mtf_openline ul.mtf_openline,
body.mtf_platinum div.mtf_platinum,
body.mtf_platinum li.mtf_platinum,
body.mtf_platinum p.mtf_platinum,
body.mtf_platinum span.mtf_platinum,
body.mtf_platinum ul.mtf_platinum,
body.mtf_portico div.mtf_portico,
body.mtf_portico li.mtf_portico,
body.mtf_portico p.mtf_portico,
body.mtf_portico span.mtf_portico,
body.mtf_portico ul.mtf_portico,
body.mtf_properties div.mtf_properties,
body.mtf_properties li.mtf_properties,
body.mtf_properties p.mtf_properties,
body.mtf_properties span.mtf_properties,
body.mtf_properties ul.mtf_properties,
body.mtf_residential_sales_staff div.mtf_residential_sales_staff,
body.mtf_residential_sales_staff li.mtf_residential_sales_staff,
body.mtf_residential_sales_staff p.mtf_residential_sales_staff,
body.mtf_residential_sales_staff span.mtf_residential_sales_staff,
body.mtf_residential_sales_staff ul.mtf_residential_sales_staff,
body.mtf_retail_owners div.mtf_retail_owners,
body.mtf_retail_owners li.mtf_retail_owners,
body.mtf_retail_owners p.mtf_retail_owners,
body.mtf_retail_owners span.mtf_retail_owners,
body.mtf_retail_owners ul.mtf_retail_owners,
body.mtf_signature div.mtf_signature,
body.mtf_signature li.mtf_signature,
body.mtf_signature p.mtf_signature,
body.mtf_signature span.mtf_signature,
body.mtf_signature ul.mtf_signature,
body.mtf_sister_companies div.mtf_sister_companies,
body.mtf_sister_companies li.mtf_sister_companies,
body.mtf_sister_companies p.mtf_sister_companies,
body.mtf_sister_companies span.mtf_sister_companies,
body.mtf_sister_companies ul.mtf_sister_companies,
body.mtf_softsurface_tms div.mtf_softsurface_tms,
body.mtf_softsurface_tms li.mtf_softsurface_tms,
body.mtf_softsurface_tms p.mtf_softsurface_tms,
body.mtf_softsurface_tms span.mtf_softsurface_tms,
body.mtf_softsurface_tms ul.mtf_softsurface_tms,
body.mtf_pergo div.mtf_pergo,
body.mtf_pergo li.mtf_pergo,
body.mtf_pergo p.mtf_pergo,
body.mtf_pergo span.mtf_pergo,
body.mtf_pergo ul.mtf_pergo,
body.mtf_quickstep div.mtf_quickstep,
body.mtf_quickstep li.mtf_quickstep,
body.mtf_quickstep p.mtf_quickstep,
body.mtf_quickstep span.mtf_quickstep,
body.mtf_quickstep ul.mtf_quickstep {
    display: block;
}

body.mtf_advertising_agencies table.mtf_advertising_agencies,
body.mtf_aligned_vendors table.mtf_aligned_vendors,
body.mtf_builder table.mtf_builder,
body.mtf_colorcenter table.mtf_colorcenter,
body.mtf_edge table.mtf_edge,
body.mtf_edge_preferred table.mtf_edge_preferred,
body.mtf_edge_premier table.mtf_edge_premier,
body.mtf_edge_select table.mtf_edge_select,
body.mtf_floorscapes table.mtf_floorscapes,
body.mtf_hardsurface_tms table.mtf_hardsurface_tms,
body.mtf_independent_aligned table.mtf_independent_aligned,
body.mtf_international table.mtf_international,
body.mtf_karastan table.mtf_karastan,
body.mtf_karastan_tms table.mtf_karastan_tms,
body.mtf_marketing table.mtf_marketing,
body.mtf_mohawk_employees table.mtf_mohawk_employees,
body.mtf_nfa_smart_solutions table.mtf_nfa_smart_solutions,
body.mtf_installers table.mtf_installers,
body.mtf_omnify table.mtf_omnify,
body.mtf_openline table.mtf_openline,
body.mtf_platinum table.mtf_platinum,
body.mtf_portico table.mtf_portico,
body.mtf_properties table.mtf_properties,
body.mtf_residential_sales_staff table.mtf_residential_sales_staff,
body.mtf_retail_owners table.mtf_retail_owners,
body.mtf_signature table.mtf_signature,
body.mtf_sister_companies table.mtf_sister_companies,
body.mtf_softsurface_tms table.mtf_softsurface_tms,
body.mtf_pergo table.mtf_pergo,
body.mtf_quickstep table.mtf_quickstep {
    display: table;
}

/* End permission related hide/reveal selectors */
body.col-one.col-two.col-content #portal-column-content,
body.col-one.col-two.col-content article#portal-column-content {
  width: 100%;
}
@media (min-width: 992px) {
  body.col-one.col-two.col-content #portal-column-content,
  body.col-two.col-content #portal-column-content, 
  body.col-one.col-content #portal-column-content, 
  body.col-one.col-content article#portal-column-content, 
  body.col-one.col-two.col-content article#portal-column-content {
      width: 100%;
  }
}

.mt-search-ayt-container {
  color: #000;
}

#content div.controlPanel nav ul.configlets {
  height: auto;
  font-size: 0.5rem;

}

#content div.controlPanel a.btn {
    padding: 0.5rem;
    background-color: transparent;
}

#content .dataTables_wrapper .btn, 
#content .navbar .btn, 
#content ol.configlets a.btn, 
#content ol.configlets a.btn {

    padding: 0.3rem;
    color: #000;
    background-color: transparent;
    border: 1px solid #ddd;
}

input.form-check[type=checkbox]:empty,
.form-check-input input[type=checkbox]:empty,
.form-check-input input[type=checkbox],
div.form-check input[type=checkbox]:empty,
div.field input[type=checkbox]:empty  {
  height: 1rem;
  margin: 5px;
}

#content table.table tr,
#content table.table th.rotate,
#content table.table th.rotate div {
  vertical-align: baseline;
}

#content table th div {
  vertical-align: baseline;
}

@charset "UTF-8";

@font-face {
  font-family: cassialight;
  src: url(https://s3.amazonaws.com/mt3-resource/universal/fonts/cassia/CassiaLig-webfont.eot);
  src: url(https://s3.amazonaws.com/mt3-resource/universal/fonts/cassia/CassiaLig-webfont.eot#iefix)
      format("embedded-opentype"),
    url(https://s3.amazonaws.com/mt3-resource/universal/fonts/cassia/CassiaLig-webfont.woff)
      format("woff"),
    url(https://s3.amazonaws.com/mt3-resource/universal/fonts/cassia/CassiaLig-webfont.ttf)
      format("truetype"),
    url(https://s3.amazonaws.com/mt3-resource/universal/fonts/cassia/CassiaLig-webfont.svg#cassialight)
      format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: cassialight_italic;
  src: url(https://s3.amazonaws.com/mt3-resource/universal/fonts/cassia/CassiaLigIta-webfont.eot);
  src: url(https://s3.amazonaws.com/mt3-resource/universal/fonts/cassia/CassiaLigIta-webfont.eot#iefix)
      format("embedded-opentype"),
    url(https://s3.amazonaws.com/mt3-resource/universal/fonts/cassia/CassiaLigIta-webfont.woff)
      format("woff"),
    url(https://s3.amazonaws.com/mt3-resource/universal/fonts/cassia/CassiaLigIta-webfont.ttf)
      format("truetype"),
    url(https://s3.amazonaws.com/mt3-resource/universal/fonts/cassia/CassiaLigIta-webfont.svg#cassialight_italic)
      format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: cassiaextralight;
  src: url(https://s3.amazonaws.com/mt3-resource/universal/fonts/cassia/CassiaXLig-webfont.eot);
  src: url(https://s3.amazonaws.com/mt3-resource/universal/fonts/cassia/CassiaXLig-webfont.eot#iefix)
      format("embedded-opentype"),
    url(https://s3.amazonaws.com/mt3-resource/universal/fonts/cassia/CassiaXLig-webfont.woff)
      format("woff"),
    url(https://s3.amazonaws.com/mt3-resource/universal/fonts/cassia/CassiaXLig-webfont.ttf)
      format("truetype"),
    url(https://s3.amazonaws.com/mt3-resource/universal/fonts/cassia/CassiaXLig-webfont.svg#cassiaextralight)
      format("svg");
  font-weight: 400;
  font-style: normal;
}

:root {
  /* Set 1rem to 18px */
  font-size: 18px;
  
  /* Colors: */
  --mt-color-white: #FFFFFF;
  --mt-color-primary-black: #000000;
  --mt-color-primary-red: #CE0E2D;
  --mt-color-secondary-grey: #4B4D4A;
  --mt-color-secondary-grey-lighter:#6A6E68;
  --mt-color-secondary-red: #AC0E28;
  --mt-color-disabled-grey: #B1B1B1;
  --mtt-private-item-color: #D0A72A;

  /* Measurements: */
  --mt-button-border-radius: 0.667rem;
  --mt-button-border-radius-mobile: 0.556rem;
  --mt-dialog-border-radius: 0.889rem;
  --mt-header-horizontal-padding: 7.5vw;
  --mt5-header-horizontal-padding: 1.5rem;
  --mt-footer-horizontal-padding: 9.375vw;
  --mt-footer-horizontal-padding-inner: 2.56rem;
  --mt-small-button-height: 2.556rem;
  --mt-small-button-height-mobile: 1.667rem;
  --mt-theme-header-margin-bottom: 0.56rem;

  /* Text: */
  --mt-text-size-large: 1.39rem;
  --mt-text-size-large-line-height: 1.15;
  --mt-text-size-normal: 1rem;
  --mt-text-size-normal-line-height: 1.4;
  --mt-text-size-small: 0.778rem;
  --mt-text-size-small-line-height: 1.5;
  --mt-text-size-extra-small: 0.611rem;
  --mt-text-size-extra-small-line-height: 1.88;

  /* Container width unit */
  --mt-container-width-unit: min(1vw, 25px);

  /* Mobile Nav */
  --mtt-nav-container-mobile-width: min(500px, 78.37vw);
}

@media(max-width: 1200px) {
  :root {
    --mt-theme-header-margin-bottom: 0.167rem;
    --mt5-header-horizontal-padding: 0.89rem;
  }
}

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  /* the two rules below are groundwork to enable sliding the body with mobile nav menu */
  position: relative;
  right: 0;
  /* transition: right 0.3s 0.085s ease; */
  transition: right 0.3s ease;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:after, blockquote:before, q:after, q:before {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.clearfix:after, .clearfix:before {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

@media (max-width: 47.99em) {
  .hide-mobile {
    display: none;
  }
}

@media (min-width: 48em) {
  .hide-desktop, .show-mobile {
    display: none;
  }
}

*, :after, :before {
  box-sizing: border-box;
}

body {
  background-color: #FFFFFF;
  background-color: var(--mt-color-white);
  color: #000000;
  color: var(--mt-color-primary-black);
}

.container {
  max-width: 61.26em;
  margin: 0 auto;
}

@media (min-width: 980.01px) and (max-width: 992px) {
  .container {
    width: 970px;
  }
}

.directive {
  display: block;
}

.relative {
  position: relative;
}

#content .cc-horz-checklisting > li {
  display: inline-block;
}

.button-group {
  text-align: center;
}

.button-group a {
  display: inline-block;
  margin-right: 15px;
  font-size: 0.825em;
}

.button-group a:last-child {
  margin-right: 0;
}

.button-group .button {
  min-width: 17em;
}

.button-group .button:first-of-type {
  margin-left: 0;
}

@media (min-width: 48em) {
  .vertical-center {
    position: relative;
    top: 45%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

body {
  font-family: Nunito, sans-serif;
  font-size: 18px;
  line-height: 1.4;
}

a {
  color: #000000;
  color: var(--mt-color-primary-black);
  text-decoration: none;
}

a:active {
  color: #4B4D4A;
  color: var(--mt-color-secondary-grey);
  font-weight: bold;
}

a:hover {
  color: #CE0E2D;
  color: var(--mt-color-primary-red);
  font-weight: bold;
  text-decoration: none;
}

a:active, a:focus {
  outline: 0;
}

a > sup {
  display: inline-block;
}

strong {
  font-weight: 700;
}

em {
  font-style: italic;
}

sup {
  position: relative;
  top: -0.5em;
  font-size: 65%;
  vertical-align: baseline;
  line-height: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Nunito, sans-serif;
  font-weight: 400;
  line-height: 1.5em;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  text-decoration: none;
}

h1 {
  font-size: 1.5625em;
}

h2 {
  font-size: 1.75em;
}

h3 {
  font-size: 1em;
  text-transform: uppercase;
}

#pamaincontent h3 {
  text-transform: none;
}

h4 {
  font-size: 1.428571429;
}

h5 {
  font-size: 0.875em;
}

h6 {
  font-size: 0.75em;
  letter-spacing: 0.1em;
}

p {
  font-size: 100%;
  margin: 0 0 1em;
}

#pamaincontent p {
  font-size: 1rem;
}

p a {
  font-size: 1rem;
}

p.large {
  font-size: 1.39rem;
  font-size: var(--mt-text-size-large);
  line-height: 1.15;
  line-height: var(--mt-text-size-large-line-height);
}

hr {
  height: 1px;
}

code, pre {
  font-family: monospace, serif;
  font-size: 1em;
}

.serif {
  font-family: cassialight, Georgia, Times New Roman, Times, serif;
}

.serif-light-italic {
  font-family: cassialight_italic, Georgia, Times New Roman, Times, serif;
}

.serif-xlight {
  font-family: cassiaextralight, Georgia, Times New Roman, Times, serif;
}

.sans {
  font-family: Nunito, sans-serif;
}

.cap {
  text-transform: uppercase;
}

.centered {
  text-align: center;
}

@media (min-width: 48em) {
  h1, h2 {
    font-size: 31px;
  }
  h3 {
    font-size: 21px;
  }
  h4 {
    font-size: 16px;
  }
  h5 {
    font-size: 13px;
  }
  h6 {
    font-size: 11px;
  }
}

::-webkit-input-placeholder {
  font-family: Nunito, sans-serif;
}

:-moz-placeholder, ::-moz-placeholder {
  font-family: Nunito, sans-serif;
}

:-ms-input-placeholder {
  font-family: Nunito, sans-serif;
}

input[type="email"],
input[type="password"],
input[type="tel"],
input[type="text"],
textarea {
  width: 100%;
  margin: 0 auto;
  padding: 0.301428571em 12px;
  font-size: 14px;
  font-family: Nunito, sans-serif;
  border: 1px solid #bdbbb7;
  transition: all 1s;
  outline: none;
  border-radius: 0;
  letter-spacing: 0.05em;
  -webkit-appearance: none;
  appearance: none;
}

input[type="email"]:focus,
input[type="password"]:focus,
input[type="tel"]:focus,
input[type="text"]:focus,
#content input:focus,
#content textarea:focus {
  transition: all 1s;
  border: 1px solid #3d3a39;
  box-shadow: none;
}

.checkbox input[type="checkbox"] {
  margin: 2px 0 0 -20px;
}

.checkbox label > input[type="checkbox"] + * {
  margin-left: 5px;
}

input[type="radio"], input[type="checkbox"] {
  float: left;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  color: #e0393e;
  border: 1px solid #bdbbb7;
  outline: none;
  transition: all 1s;
}

.radio input[type="radio"] {
  position: relative;
  margin-left: 0;
}

input[type="radio"] + label {
  padding: 0 0 0 3px;
}

input[type="checkbox"] + label {
  padding: 2px 0 0 3px;
}

input[type="checkbox"]:before {
  display: inline-block;
  opacity: 0;
  -webkit-transform: scale(1);
  transform: scale(1);
  transition: all 0.1s;
}

input[type="checkbox"]:checked:before {
  opacity: 1;
  color: #e0393e;
  -webkit-transform: scale(1.25);
  transform: scale(1.25);
  transition: all 0.1s;
}

input[type="checkbox"]:focus {
  border: 1px solid #3d3a39;
}

input[type="checkbox"]:focus, select {
  transition: all 1s;
}

select {
  padding: 5px;
  border-radius: 0;
  border: 1px solid #bdbbb7;
  background: none;
  outline: none;
  letter-spacing: 0.05em;
  -webkit-appearance: none;
  appearance: none;
}

select:focus, select.form-control:focus {
  border: 1px solid #3d3a39;
  box-shadow: none;
}

select:focus, select:focus + i {
  transition: all 1s;
}

select:focus + i {
  color: #3d3a39;
}

.select-wrap {
  position: relative;
  display: inline-block;
  overflow: hidden;
  border: 1px solid #bdbbb7;
}

.select-wrap select {
  width: calc(100% + 4.2em);
  border: none;
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;
}

.select-wrap i {
  position: absolute;
  top: 19px;
  right: 6px;
  color: #a3a09d;
  pointer-events: none;
  transition: all 1s;
}

textarea {
  min-height: 10em;
}

button:active, button:focus {
  outline: none;
}

label {
  display: inline-block;
  text-indent: 0;
  font-size: 0.9em;
  letter-spacing: 0.05em;
  margin-bottom: 5px;
  color: #a3a09d;
}

.fields {
  width: 100%;
}

.fields:after, .fields:before {
  content: " ";
  display: table;
}

.fields:after {
  clear: both;
}

@media (min-width: 48em) and (max-width: 979px) {
  .fields {
    padding: 0 1.1em;
  }
}

.fields.two-up .field {
  width: 86.6666667%;
  margin-right: 6.66666665%;
}

.field {
  float: left;
  width: 93.877551%;
  height: auto;
  margin: 0 3.0612245%;
  padding-top: 3.0612245%;
}

@media (min-width: 48em) {
  .field {
    width: 100%;
    margin: 0 auto;
    padding-top: 3.0612245%;
  }
}

.field-error input[type="email"],
.field-error input[type="password"],
.field-error input[type="text"] {
  border: 1px solid #e0393e;
}

.field-error label {
  opacity: 1 !important;
  top: 8px !important;
  color: #e0393e;
}

.animate-label {
  position: relative;
  padding-top: 1.875em !important;
}

.animate-label label {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  top: 12px;
  left: 0;
  transition: all 0.25s;
}

.animate-label.animate-label-show-label label,
.animate-label input:focus + label,
.animate-label textarea:focus + label {
  top: 8px;
  opacity: 1;
  transition: all 0.25s;
}

#content #banner-slider {
  min-height: 250px;
}

#content #homepage-article {
  min-height: 50vh;
}

#content #mt-dashboard-mount {
  min-height: 22.5em;
}

#pamaincontent .btn.btn-danger {
  font-size: 1rem;
  text-transform: none;
}

.button, a.button, #content a.btn, .btn, a.btn, input.searchButton {
  display: inline-block;
  min-width: 9rem;
  padding: 1.1rem 2rem;
  border: 1px solid transparent;
  background: #CE0E2D;
  background: var(--mt-color-primary-red);
  border-radius: 0.667rem;
  border-radius: var(--mt-button-border-radius);
  cursor: pointer;
  color: #FFFFFF;
  color: var(--mt-color-white);
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  text-shadow: none;
  transition: all 0.25s;
  -webkit-appearance: none;
          appearance: none;
  position: relative;
  box-sizing: border-box;
}

.button + .button, .btn + .btn {
  margin-left: 1rem;
}


.button.button-small, a.button.button-small {
  min-width: 2.556rem;
  min-width: var(--mt-small-button-height);
  min-height: 2.556rem;
  min-height: var(--mt-small-button-height);
  font-weight: normal;
  padding: 0.778rem;
  line-height: 1;
}

.mtt-mobile .button.button-small, .mtt-mobile a.button.button-small {
  min-width: 1.667rem;
  min-width: var(--mt-small-button-height-mobile);
  min-height: 1.667rem;
  min-height: var(--mt-small-button-height-mobile);
  font-size: 0.778rem;
  font-size: var(--mt-text-size-small);
  padding: 0.399rem;
}

.button.button-edge-icon  {
  width: 4.72rem;
  flex-shrink: 0;
}

.button.button-small-icon img, .button.button-edge-icon img, .button.button-small-icon .mtt-faq-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.button.button-small-icon .mtt-faq-icon {
  font-weight: bold;
  font-size: 1.39rem;
  font-size: var(--mt-text-size-large);
}


.button.button-primary, a.button.button-primary {
  background: #CE0E2D;
  background: var(--mt-color-primary-red);
}

#content a.btn.hover, #content .btn:hover, #content .btn:focus, #content .btn:active,
.btn:hover, .btn:focus, .btn:active,
.btn.hover, .btn:focus:active, a.btn.hover, input.searchButton.hover, 
.button:hover, a.button:hover, .button.button-primary:hover, a.button.button-primary:hover,
.button:active, a.button:active, .button.button-primary:active, a.button.button-primary:active {
  background: #AC0E28;
  background: var(--mt-color-secondary-red);
  color: #FFFFFF;
  color: var(--mt-color-white);
  transition: background 0.2s;
  box-shadow: none;
  outline: none;
}

#content a.btn:disabled, #content .btn:disabled, .btn:disabled, 
.button:disabled, a.button:disabled, .button.button-primary:disabled, a.button.button-primary:disabled {
  opacity: 0.5;
  color: #B1B1B1;
  color: var(--mt-color-disabled-grey);
}

.button.button-secondary, a.button.button-secondary, #content .button.button-secondary, #content a.button.button-secondary  {
  background: #000000;
  background: var(--mt-color-primary-black);
}

.button.button-secondary:hover, a.button.button-secondary:hover, #content .button.button-secondary:hover, #content a.button.button-secondary:hover {
  background: #4B4D4A;
  background: var(--mt-color-secondary-grey);
}

.button.button-secondary:disabled, a.button.button-secondary:disabled, #content .button.button-secondary:disabled, #content a.button.button-secondary:disabled {
  background: #030001;
  opacity: 0.5;
  color: #B1B1B1;
  color: var(--mt-color-disabled-grey);
}

.button.button-outline, a.button.button-outline, #content .button.button-outline, #content a.button.button-outline {
  background: #FFFFFF;
  background: var(--mt-color-white);
  color: #CE0E2D;
  color: var(--mt-color-primary-red);
  border: 1px solid #CE0E2D;
  border: 1px solid var(--mt-color-primary-red);
}

.button.button-outline:hover, a.button.button-outline:hover, #content .button.button-outline:hover, #content a.button.button-outline:hover {
  background: #CE0E2D;
  background: var(--mt-color-primary-red);
  color: #FFFFFF;
  color: var(--mt-color-white);
}

.button.button-outline:disabled, a.button.button-outline:disabled, #content .button.button-outline:disabled, #content a.button.button-outline:disabled {
  background: #FFFFFF;
  background: var(--mt-color-white);
  opacity: 0.5;
  color: #B1B1B1;
  color: var(--mt-color-disabled-grey);
}

input[type="submit"]:disabled {
  opacity: 0.3;
  transition: opacity 0.3s linear;
}

@media (min-width: 48em) {
  .fields.two-up .field {
    width: 48.46938775%;
    margin-right: 3.0612245%;
  }
  .fields.two-up .field:nth-child(2n) {
    margin-right: 0;
  }
}

div.documentEditable #edit-bar {
  min-height: 2.4em;
}

@media (max-width: 1200px) {
  #viewlet-below-content {
    display: none;
  }
}

#viewlet-above-content {
  padding: 10px 0;
}

#viewlet-above-content:empty {
  display: none;
}

@media (min-width: 1201px) {
  #viewlet-above-content {
    max-width: 61.26em;
    margin-left: auto;
    margin-right: auto;
  }
}

#viewlet-above-content #portal-breadcrumbs a {
  text-decoration: none;
}

@media (max-width: 1200px) {
  #viewlet-above-content {
    display: none;
  }
}

body#content {
  padding: 30px;
}

.state-private {
  color: #CE0E2D;
  color: var(--mt-color-primary-red);
}

.row.mt_m_five .col-lg-1,
.row.mt_m_five .col-lg-2,
.row.mt_m_five .col-lg-3,
.row.mt_m_five .col-lg-4,
.row.mt_m_five .col-lg-5,
.row.mt_m_five .col-lg-6,
.row.mt_m_five .col-lg-7,
.row.mt_m_five .col-lg-8,
.row.mt_m_five .col-lg-9,
.row.mt_m_five .col-lg-10,
.row.mt_m_five .col-lg-11,
.row.mt_m_five .col-lg-12,
.row.mt_m_five .col-md-1,
.row.mt_m_five .col-md-2,
.row.mt_m_five .col-md-3,
.row.mt_m_five .col-md-4,
.row.mt_m_five .col-md-5,
.row.mt_m_five .col-md-6,
.row.mt_m_five .col-md-7,
.row.mt_m_five .col-md-8,
.row.mt_m_five .col-md-9,
.row.mt_m_five .col-md-10,
.row.mt_m_five .col-md-11,
.row.mt_m_five .col-md-12,
.row.mt_m_five .col-sm-1,
.row.mt_m_five .col-sm-2,
.row.mt_m_five .col-sm-3,
.row.mt_m_five .col-sm-4,
.row.mt_m_five .col-sm-5,
.row.mt_m_five .col-sm-6,
.row.mt_m_five .col-sm-7,
.row.mt_m_five .col-sm-8,
.row.mt_m_five .col-sm-9,
.row.mt_m_five .col-sm-10,
.row.mt_m_five .col-sm-11,
.row.mt_m_five .col-sm-12,
.row.mt_m_five .col-xs-1,
.row.mt_m_five .col-xs-2,
.row.mt_m_five .col-xs-3,
.row.mt_m_five .col-xs-4,
.row.mt_m_five .col-xs-5,
.row.mt_m_five .col-xs-6,
.row.mt_m_five .col-xs-7,
.row.mt_m_five .col-xs-8,
.row.mt_m_five .col-xs-9,
.row.mt_m_five .col-xs-10,
.row.mt_m_five .col-xs-11,
.row.mt_m_five .col-xs-12 {
  padding-left: 5px;
  padding-right: 5px;
}

.row.mt_m_ten .col-lg-1,
.row.mt_m_ten .col-lg-2,
.row.mt_m_ten .col-lg-3,
.row.mt_m_ten .col-lg-4,
.row.mt_m_ten .col-lg-5,
.row.mt_m_ten .col-lg-6,
.row.mt_m_ten .col-lg-7,
.row.mt_m_ten .col-lg-8,
.row.mt_m_ten .col-lg-9,
.row.mt_m_ten .col-lg-10,
.row.mt_m_ten .col-lg-11,
.row.mt_m_ten .col-lg-12,
.row.mt_m_ten .col-md-1,
.row.mt_m_ten .col-md-2,
.row.mt_m_ten .col-md-3,
.row.mt_m_ten .col-md-4,
.row.mt_m_ten .col-md-5,
.row.mt_m_ten .col-md-6,
.row.mt_m_ten .col-md-7,
.row.mt_m_ten .col-md-8,
.row.mt_m_ten .col-md-9,
.row.mt_m_ten .col-md-10,
.row.mt_m_ten .col-md-11,
.row.mt_m_ten .col-md-12,
.row.mt_m_ten .col-sm-1,
.row.mt_m_ten .col-sm-2,
.row.mt_m_ten .col-sm-3,
.row.mt_m_ten .col-sm-4,
.row.mt_m_ten .col-sm-5,
.row.mt_m_ten .col-sm-6,
.row.mt_m_ten .col-sm-7,
.row.mt_m_ten .col-sm-8,
.row.mt_m_ten .col-sm-9,
.row.mt_m_ten .col-sm-10,
.row.mt_m_ten .col-sm-11,
.row.mt_m_ten .col-sm-12,
.row.mt_m_ten .col-xs-1,
.row.mt_m_ten .col-xs-2,
.row.mt_m_ten .col-xs-3,
.row.mt_m_ten .col-xs-4,
.row.mt_m_ten .col-xs-5,
.row.mt_m_ten .col-xs-6,
.row.mt_m_ten .col-xs-7,
.row.mt_m_ten .col-xs-8,
.row.mt_m_ten .col-xs-9,
.row.mt_m_ten .col-xs-10,
.row.mt_m_ten .col-xs-11,
.row.mt_m_ten .col-xs-12 {
  padding-left: 10px;
  padding-right: 10px;
}

.row.mt_m_zero .col-lg-1,
.row.mt_m_zero .col-lg-2,
.row.mt_m_zero .col-lg-3,
.row.mt_m_zero .col-lg-4,
.row.mt_m_zero .col-lg-5,
.row.mt_m_zero .col-lg-6,
.row.mt_m_zero .col-lg-7,
.row.mt_m_zero .col-lg-8,
.row.mt_m_zero .col-lg-9,
.row.mt_m_zero .col-lg-10,
.row.mt_m_zero .col-lg-11,
.row.mt_m_zero .col-lg-12,
.row.mt_m_zero .col-md-1,
.row.mt_m_zero .col-md-2,
.row.mt_m_zero .col-md-3,
.row.mt_m_zero .col-md-4,
.row.mt_m_zero .col-md-5,
.row.mt_m_zero .col-md-6,
.row.mt_m_zero .col-md-7,
.row.mt_m_zero .col-md-8,
.row.mt_m_zero .col-md-9,
.row.mt_m_zero .col-md-10,
.row.mt_m_zero .col-md-11,
.row.mt_m_zero .col-md-12,
.row.mt_m_zero .col-sm-1,
.row.mt_m_zero .col-sm-2,
.row.mt_m_zero .col-sm-3,
.row.mt_m_zero .col-sm-4,
.row.mt_m_zero .col-sm-5,
.row.mt_m_zero .col-sm-6,
.row.mt_m_zero .col-sm-7,
.row.mt_m_zero .col-sm-8,
.row.mt_m_zero .col-sm-9,
.row.mt_m_zero .col-sm-10,
.row.mt_m_zero .col-sm-11,
.row.mt_m_zero .col-sm-12,
.row.mt_m_zero .col-xs-1,
.row.mt_m_zero .col-xs-2,
.row.mt_m_zero .col-xs-3,
.row.mt_m_zero .col-xs-4,
.row.mt_m_zero .col-xs-5,
.row.mt_m_zero .col-xs-6,
.row.mt_m_zero .col-xs-7,
.row.mt_m_zero .col-xs-8,
.row.mt_m_zero .col-xs-9,
.row.mt_m_zero .col-xs-10,
.row.mt_m_zero .col-xs-11,
.row.mt_m_zero .col-xs-12 {
  padding-left: 0;
  padding-right: 0;
}

#pamaincontent table td, .fullwidth table td, .mh-specialsection table td {
  vertical-align: top;
}

#ssoModal {
  top: 5%;
}

@media (min-width: 980px) {
  #content nav, #pamaincontent nav, nav.mtnav {
    margin-left: auto;
    margin-right: auto;
    width: 850px;
  }
}

@media (min-width: 980px) {
  #content nav.eight li a, #pamaincontent nav.eight li a, nav.mtnav.eight li a {
    width: 99.25px;
  }
  #content nav.eight li:last-child a,
  #pamaincontent nav.eight li:last-child a,
  nav.mtnav.eight li:last-child a {
    width: 100.25px;
  }
}

@media (min-width: 980px) {
  #content nav.seven li a, #pamaincontent nav.seven li a, nav.mtnav.seven li a {
    width: 116.428571429px;
  }
  #content nav.seven li:last-child a,
  #pamaincontent nav.seven li:last-child a,
  nav.mtnav.seven li:last-child a {
    width: 117.428571429px;
  }
}

@media (min-width: 980px) {
  #content nav.six li a, #pamaincontent nav.six li a, nav.mtnav.six li a {
    width: 136.666666667px;
  }
  #content nav.six li:last-child a,
  #pamaincontent nav.six li:last-child a,
  nav.mtnav.six li:last-child a {
    width: 137.666666667px;
  }
}

@media (min-width: 980px) {
  #content nav.five li a, #pamaincontent nav.five li a, nav.mtnav.five li a {
    width: 165px;
  }
  #content nav.five li:last-child a,
  #pamaincontent nav.five li:last-child a,
  nav.mtnav.five li:last-child a {
    width: 166px;
  }
}

@media (min-width: 980px) {
  #content nav.four li a, #pamaincontent nav.four li a, nav.mtnav.four li a {
    width: 207.5px;
  }
  #content nav.four li:last-child a,
  #pamaincontent nav.four li:last-child a,
  nav.mtnav.four li:last-child a {
    width: 208.5px;
  }
}

@media (min-width: 980px) {
  #content nav.three li a, #pamaincontent nav.three li a, nav.mtnav.three li a {
    width: 278.333333333px;
  }
  #content nav.three li:last-child a,
  #pamaincontent nav.three li:last-child a,
  nav.mtnav.three li:last-child a {
    width: 279.333333333px;
  }
}

#content nav ul, #pamaincontent nav ul, nav.mtnav ul {
  margin: 0 auto;
  padding-top: 20px;
  display: block;
  transition: opacity 0.2s linear;
  overflow-y: auto;
  font-size: 1.2em;
}

#content nav ul.nav-tabs {
  overflow-y: hidden;
}

@media (min-width: 980px) {
  #content nav ul, #pamaincontent nav ul, nav.mtnav ul {
    margin-top: 0;
    padding: 10px 0;
    overflow: hidden;
    display: block;
    float: none;
    height: 60px;
    width: 100%;
  }
}

#content nav ul > li, #pamaincontent nav ul > li, nav.mtnav ul > li {
  display: block;
  float: left;
  line-height: 2em;
  width: 50%;
  list-style-type: none;
  margin: 0;
  text-align: center;
  border: 1px solid #eee;
  border-collapse: collapse;
}

@media (min-width: 980px) {
  #content nav ul > li, #pamaincontent nav ul > li, nav.mtnav ul > li {
    line-height: 1em;
    border: 0;
    display: inline-block;
    float: none;
    width: auto;
  }
}

#content nav ul > li > a,
#content nav ul > li span a,
#pamaincontent nav ul > li > a,
#pamaincontent nav ul > li span a,
nav.mtnav ul > li > a,
nav.mtnav ul > li span a {
  color: #383838;
  text-decoration: none;
  font-size: 0.7em;
  padding: 0;
}

/* TODO: change this to 1rem? */
#pamaincontent nav ul > li > a, #pamaincontent nav ul > li span a {
  font-size: 16px;
}

@media (min-width: 980px) {
  #content nav ul > li > a,
  #content nav ul > li span a,
  #pamaincontent nav ul > li > a,
  #pamaincontent nav ul > li span a,
  nav.mtnav ul > li > a,
  nav.mtnav ul > li span a {
    display: block;
    padding: 1em;
  }
}

fieldset#folder-listing {
  display: block;
  width: 100%;
}

form.rowlike div.field {
  float: none;
  height: auto;
}

form.rowlike fieldset {
  border: 1px solid #eee;
  min-width: 100%;
}

label span.formHelp {
  display: none;
}

label:hover span.formHelp {
  display: inline;
  margin-left: 20px;
  color: #000;
}

input.datetime-field, td.field {
  width: auto;
}

ul.cc-horz-checklisting li a {
  text-decoration: none;
}

dl.portlet.container {
  margin: 0 auto;
}

dt.portletHeader {
  margin-bottom: 20px;
}

#content div#pamaincontent div.plain {
  padding: 0;
}

table tr td.field {
  float: none;
}

#portal-column-content #content table.invisible {
  visibility: visible;
}

#content-views,
#contentActionMenus,
#contentActionMenus dl.actionMenu a,
#edit-bar {
  background-color: #777;
}

@media (max-width: 47.99em) {
  #content-views,
  #contentActionMenus,
  #contentActionMenus dl.actionMenu a,
  #edit-bar {
    display: none;
  }
}

#edit-bar {
  border: 0.25em solid #777;
  margin-top: 2.1428571429em;
}

@media (min-width: 980px) {
  #edit-bar {
    margin-left: auto;
    margin-right: auto;
  }
}

#content-views a {
  line-height: 24px;
}

#content-views, #contentActionMenus {
  border-color: #777;
}

#content-views li, #contentActionMenus li {
  display: inline;
}

#content-views li a, #contentActionMenus li a {
  text-decoration: none;
}

#content-views dl.actionMenu.activated dd,
#contentActionMenus dl.actionMenu.activated dd {
  background-color: #777;
  border-color: #777;
}

#content-views dl.actionMenu.activated dd ul li a img,
#contentActionMenus dl.actionMenu.activated dd ul li a img {
  display: none;
}

@media (max-width: 47.99em) {
  #kss-menu {
    display: none;
  }
}

#kss-menu li {
  background: none;
}

#kss-menu li:before {
  font-family: FontAwesome;
  top: 0;
  left: -5px;
  padding-right: 7px;
  content: "\f093";
}

#kss-menu li a.kss-menu-text {
  border-bottom: 0;
  color: #e0393e;
}

dl.searchResults {
  font-size: 1.1em;
}

dl.searchResults dt {
  font-weight: 700;
}

dl.searchResults dt a {
  text-decoration: none;
}

dl.searchResults dt img {
  display: none;
}

dl.searchResults dd {
  margin-bottom: 20px;
}

input.searchPage {
  font-size: 100% !important;
}

input.searchButton {
  margin-top: 5px;
  margin-bottom: 20px;
}

footer {
  /* margin-top: 2.5em; */
  color: #FFFFFF;
  color: var(--mt-color-white);
  background: #000000;
  background: var(--mt-color-primary-black);
}

.site-header {
  position: relative;
  display: block;
  background: #000000;
  background: var(--mt-color-primary-black);
  z-index: 14;
  margin-bottom: 0.56rem;
  margin-bottom: var(--mt-theme-header-margin-bottom);
}

.mt-text-large {
  font-size: 1.39rem;
  font-size: var(--mt-text-size-large);
  line-height: 1.15;
  line-height: var(--mt-text-size-large-line-height);
  font-weight: bold;
  letter-spacing: 0;
}
.mt-text-normal {
  font-size: 1rem;
  font-size: var(--mt-text-size-normal);
  line-height: 1.4;
  line-height: var(--mt-text-size-normal-line-height);
  letter-spacing: 0;
}
.mt-text-small {
  font-size: 0.778rem;
  font-size: var(--mt-text-size-small);
  line-height: 1.5;
  line-height: var(--mt-text-size-small-line-height);
  letter-spacing: 0;
}
.mt-text-extra-small {
  font-size: 0.611rem;
  font-size: var(--mt-text-size-extra-small);
  line-height: 1.88;
  line-height: var(--mt-text-size-extra-small-line-height);
  letter-spacing: 0;
}



.mtt-header-nav-desktop {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  padding: 3rem 4vw 8rem 7.5vw;
  background: #000000;
  background: var(--mt-color-primary-black);
  min-height: 90vh;
  /* border-top: 1px solid var(--mt-color-secondary-grey); */
  border-top: 1px solid #6A6E68;
  border-top: 1px solid var(--mt-color-secondary-grey-lighter);
  /* color: var(--mt-color-secondary-grey); */
  color: #6A6E68;
  color: var(--mt-color-secondary-grey-lighter);
}

.mtt-header-nav-desktop-inner {
  display: flex;
  position: relative;
  align-items: flex-start;
}

.mtt-header-nav-desktop-open {
  display: block;
}



.mtt-nav-fade-enter, .mtt-nav-fade-appear {
  z-index: 1;
  opacity: 0;
}

.mtt-nav-fade-enter.mtt-nav-fade-enter-active,
.mtt-nav-fade-appear.mtt-nav-fade-appear-active {
  opacity: 1;
  transition: opacity 200ms ease-in;
}

.mtt-nav-fade-exit {
  position: absolute;
  left: 0;
  opacity: 1;
  z-index: 1;
}

.mtt-nav-fade-exit.mtt-nav-fade-exit-active {
  opacity: 0;
  transition: opacity 150ms ease-in;
}

.mtt-nav-slide-left-enter, .mtt-nav-slide-left-appear {
  position: absolute;
  right: -100%;
}

.mtt-nav-slide-left-enter.mtt-nav-slide-left-enter-active,
.mtt-nav-slide-left-appear.mtt-nav-slide-left-appear-active {
  right: 0;
  transition: all 200ms ease-out;
}

.mtt-nav-slide-left-exit {
  position: absolute;
  right: 0;
}

.mtt-nav-slide-left-exit.mtt-nav-slide-left-exit-active {
  right: 100%;
  transition: all 225ms ease-out;
}

.mtt-nav-slide-right-enter, .mtt-nav-slide-right-appear {
  position: absolute;
  right: 100%;
}

.mtt-nav-slide-right-enter.mtt-nav-slide-right-enter-active,
.mtt-nav-slide-right-appear.mtt-nav-slide-right-appear-active {
  right: 0;
  transition: all 225ms ease-out;
}

.mtt-nav-slide-right-exit {
  position: absolute;
  right: 0;
}

.mtt-nav-slide-right-exit.mtt-nav-slide-right-exit-active {
  right: -100%;
  transition: all 225ms ease-out;
}

.mtt-nav-container-mobile {
  padding-left: 0.28rem;
  padding-right: 0.4rem;
  /* padding: 4rem 5.5vw 5.2rem var(--mt-header-horizontal-padding); */
  background: #000000;
  background: var(--mt-color-primary-black);
  /* color: var(--mt-color-secondary-grey); */
  color: #6A6E68;
  color: var(--mt-color-secondary-grey-lighter);
  width: 100%;
  z-index: 41;
}

@media (max-width: 600px) {
  .mtt-nav-container-mobile {
    padding-right: 6.5vw;
  }
}

/* .mtt-nav-container-mobile.mtt-nav-container-mobile-open {
  right: 0;
} */

.mtt-nav-control {
  position: absolute;
  top: -1.5rem;
  cursor: pointer;
  /* padding with negative margin adds clickable space without changing position */
  padding: 0.5rem;
  margin: -0.5rem;
  transition: all 0.2s
}

.mtt-mobile .mtt-nav-control {
  top: -2.5rem;
}

.mtt-nav-control img {
  height: 1.56rem;
}

.mtt-nav-control:hover img {
  /* transform to white */
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
}

.mtt-mobile .mtt-nav-control img {
  height: 1rem;
}

.mtt-nav-control-back-arrow img {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.mtt-nav-control-close-x {
  right: 3.74vw;
}

.mtt-mobile .mtt-nav-control-close-x {
  right: 0;
}

.mtt-no-shrink {
  flex-shrink: 0;
}

div#portal-searchbox {
  display: none;
}

.hero {
  width: 100%;
  height: 28.125em;
  background-color: #000;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 35% 0;
}

.hero .slide-content {
  position: relative;
  left: 0;
  top: 5em;
  padding: 0 1em;
  color: #fff;
  text-align: center;
}

.hero h1, .hero h5 {
  display: inline-block;
  margin-bottom: 2.071428571em;
  padding-bottom: 0.2em;
  text-transform: uppercase;
  border-bottom: 2px solid #fff;
  font-size: 0.625em;
  letter-spacing: 0.1em;
}

.hero h2 {
  max-width: 17.142857143em;
  margin: 0 auto 1.404761905em;
  line-height: 1.19047619em;
  font-size: 2em;
  font-family: cassiaextralight, Georgia, Times New Roman, Times, serif;
  letter-spacing: 0.05em;
}

@media (min-width: 48em) {
  .hero .slide-content h2 {
    font-size: 3em;
  }
}

@media (min-width: 980px) {
  .hero .slide-content h2 {
    font-size: 3.75em;
  }
}

@media screen and (min-width: 1025px) {
  .hero .slide-content h2 {
    font-size: 4.8571428571em;
  }
}

@media (min-width: 48em) {
  .hero {
    height: 36.25em;
    margin-bottom: 6.25em;
  }
  .hero .slide-content {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .hero h1, .hero h5 {
    font-size: 0.875em;
  }
  .hero h2 {
    font-size: 4.25em;
    margin-bottom: 0.65em;
  }
}

.image-hero, .image-hero-cta {
  position: relative;
}

.image-hero-cta img, .image-hero img {
  width: 100%;
}

.image-hero-cta h1, .image-hero h1 {
  font-size: 0.875em;
  border-bottom: 2px solid #fff;
  color: #fff;
  display: inline-block;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.image-hero-cta .image-content, .image-hero .image-content {
  width: 100%;
  position: absolute;
  top: 18%;
  left: 0;
  text-align: center;
}

@media (max-width: 47.99em) {
  .image-hero-cta .image-content, .image-hero .image-content {
    width: 80%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

@media (min-width: 48em) {
  .image-hero-cta .image-content, .image-hero .image-content {
    top: 25%;
  }
}

@media (min-width: 980px) {
  .image-hero-cta .image-content, .image-hero .image-content {
    top: 35%;
  }
}

.image-hero-cta h2, .image-hero h2 {
  font-size: 1.75em;
  line-height: 1.3;
  color: #fff;
  padding-bottom: 0.25em;
}

@media (min-width: 48em) {
  .image-hero-cta h2, .image-hero h2 {
    font-size: 3em;
  }
}

@media (min-width: 980px) {
  .image-hero-cta h2, .image-hero h2 {
    font-size: 3.75em;
  }
}

@media screen and (min-width: 1025px) {
  .image-hero-cta h2, .image-hero h2 {
    font-size: 4.8571428571em;
  }
}

.image-hero-cta {
  margin-bottom: 4em;
}

.image-hero-cta .image-content .btn-group {
  margin-top: 1.5em;
}

.image-hero-cta .image-content .btn-group .button {
  min-width: 200px;
}

.image-hero-cta .image-content .btn-group .button:first-of-type {
  margin-bottom: 10px;
}

.image-hero-cta h5 {
  display: inline-block;
  margin-bottom: 2.071428571em;
  padding-bottom: 0.2em;
  text-transform: uppercase;
  border-bottom: 2px solid #fff;
  font-size: 0.625em;
  letter-spacing: 0.1em;
  color: #fff;
}

.page-education .image-hero-cta {
  margin-bottom: 0;
}

.overlay-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  overflow-y: auto;
  padding: 0.5em 0;
  background: #faf8f4;
}

.overlay-container .container {
  position: relative;
}

.overlay-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

@media (min-width: 980px) {
  .overlay-content {
    width: 100%;
    margin: 0 auto;
  }
}

.overlay-content iframe {
  width: 100%;
}

.overlay-constrained {
  top: 3.125em;
  width: 87.5%;
  margin: 0 6.25%;
}

@media (min-width: 48em) {
  .overlay-constrained {
    width: 93.877551%;
    margin: 0 3.0612245%;
  }
}

@media (min-width: 980px) {
  .overlay-constrained {
    width: 100%;
    margin: 0 auto;
  }
}

@media (max-width: 47.99em) {
  .overlay-video {
    margin: 0 auto;
  }
}

.overlay-close-button {
  z-index: 1;
  position: absolute;
  top: 0.5em;
  right: 0.75em;
  font-size: 1.5em;
  text-decoration: none;
  color: #2a2a29;
}

.overlay-close-button:hover {
  text-decoration: none;
}

@media (min-width: 48em) {
  .overlay-close-button {
    font-size: 2em;
    top: 0.25em;
    right: -0.025em;
  }
}

.overlay-wistia {
  padding: 3em;
  height: 100%;
  background: #faf8f4;
}

.overlay-leave-review {
  color: #222;
}

.overlay-leave-review > section {
  text-align: left !important;
}

.overlay-leave-review header {
  margin: 1.25em 0;
  padding: 0 2rem;
  text-align: center;
}

.overlay-leave-review h3 {
  margin-bottom: 0.3em;
  font-size: 1.75em;
  font-weight: 400;
  font-family: cassialight, Georgia, Times New Roman, Times, serif;
  line-height: 105%;
}

.overlay-leave-review h4 {
  width: 75%;
  margin: 0 auto 1em;
}

.overlay-leave-review form .clearfix > h4 {
  margin-bottom: 0;
}

.overlay-leave-review h6 {
  text-transform: uppercase;
  margin-bottom: 0.75em;
}

.overlay-leave-review hr {
  width: 20px;
  margin-bottom: 4px;
  background: #777;
}

.overlay-leave-review .retailer-badge {
  font-size: 1em;
}

.overlay-leave-review .retailer-badge .badge-text {
  color: #555;
}

.overlay-leave-review form > div {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}

.overlay-leave-review .field {
  margin: 0;
  width: 100%;
}

.overlay-leave-review .field-review-title {
  margin: -1em 0 1.5em;
}

.overlay-leave-review .block-user-details {
  margin: 2em 0;
}

.overlay-leave-review .block-user-details h4 {
  margin-bottom: 0;
}

.overlay-leave-review .block-user-details h6 {
  margin: 0.5em 0;
  text-align: center;
}

.overlay-leave-review .block-user-details label {
  float: left;
  width: 80%;
  padding-top: 6px;
  font-size: 0.875em;
  font-family: cassialight, Georgia, Times New Roman, Times, serif;
  color: #222;
  text-indent: 0;
  line-height: 120%;
}

.overlay-leave-review .block-user-details li {
  margin-bottom: 0.25em;
}

.overlay-leave-review .rating-module > h6.recomend {
  line-height: 1.5;
}

.overlay-leave-review .loading-animation {
  position: absolute;
  top: 45%;
  left: 50%;
  margin-left: -58px;
}

.overlay-leave-review .error-msg {
  line-height: 1.5em;
}

.overlay-leave-review .confirmation-screen {
  text-align: center;
}

.overlay-leave-review .confirmation-screen p {
  padding: 0 10% 25px;
}

@media (min-width: 48em) {
  .overlay-leave-review .confirmation-screen {
    padding: 25px 0 65px;
  }
  .overlay-leave-review .confirmation-screen p {
    padding: 0 20% 40px;
  }
}

.overlay-sheet {
  position: relative;
  padding: 1.5em;
  background: #fff;
}

.overlay-sheet h4 {
  font-family: cassialight, Georgia, Times New Roman, Times, serif;
  font-size: 1.25em;
  text-align: center;
}

@media (min-width: 980px) {
  .overlay-sheet h4 {
    padding-top: 32px;
  }
}

@media (min-width: 980px) {
  .overlay-sheet form > .clearfix {
    margin-top: 2em;
  }
}

@media (min-width: 980px) {
  .overlay-leave-review hr {
    margin: 16px auto;
  }
  .overlay-leave-review .field-review-title {
    margin: -1.875em 0 1.5em;
  }
  .block-rate {
    float: left;
    margin: 1em 0 0 60px;
    width: 400px;
  }
  .block-review {
    float: right;
    margin: 1em 60px 0 0;
    width: 370px;
  }
  .block-review > textarea {
    height: 260px;
  }
  .block-user-info-products {
    float: left;
    margin: 1em 0 0 60px;
    width: 350px;
  }
  .block-user-details {
    float: right;
    margin: 1em 60px 0 0;
    width: 460px;
    padding-left: 2em;
    border-left: 1px solid #eeede9;
  }
  .block-user-info {
    margin: 4em auto 0;
    width: 380px;
  }
  .block-user-info h4 {
    margin-bottom: 1em;
  }
}

body.userrole-anonymous header.site-header {
  display: none;
}

.test {
  background-color: rgba(255, 0, 0, 0.5);
}

button {
  font-family: Nunito, sans-serif;
}

.fancyimg, .imageonload.image {
  opacity: 0;
}

.fancyimg.loaded, .imageonload.image.loaded {
  opacity: 1;
  transition: all 0.5s;
}

.ng-cloak {
  opacity: 0;
  display: block !important;
}

.circular-image {
  border-radius: 50%;
  overflow: hidden;
}

.link-red, .link-red:visited {
  padding: 0 0 0.125em;
  text-transform: uppercase;
  text-decoration: none;
  color: #e0393e;
  background: none;
  border: none;
  cursor: pointer;
  border-bottom: 1px solid #e0393e;
  outline: none;
}

.link-red.link-small, .link-red:visited.link-small {
  font-size: 0.6875em;
}

.link-red:hover, .link-red:visited:hover {
  color: #e43b41;
  border-bottom: 1px solid #e43b41;
  text-decoration: none;
}

.link-black, .link-black:visited {
  padding: 0 0 0.125em;
  text-transform: uppercase;
  text-decoration: none;
  color: #383838;
  background: none;
  border: none;
  cursor: pointer;
  border-bottom: 1px solid #383838;
  outline: none;
}

.link-black.link-small, .link-black:visited.link-small {
  font-size: 0.6875em;
}

.link-black:hover, .link-black:visited:hover {
  color: #525252;
  border-bottom: 1px solid #525252;
  text-decoration: none;
}

.no-scroll {
  overflow: hidden;
}

a, button {
  -webkit-tap-highlight-color: transparent;
}

.section-title {
  text-align: center;
  font-family: cassialight, Georgia, Times New Roman, Times, serif;
  font-size: 1.5em;
}

@media (min-width: 980px) {
  .section-title {
    font-size: 2.25em;
  }
}

#content #pacontent div.b3container {
  width: 1170px;
}

a.managePortletsFallback {
  display: none;
}

.submit-button-container {
  margin: 1.5em 0;
  text-align: center;
}

.form-results-container {
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
  padding: 1em;
}

.form-results-container h2 {
  margin-bottom: 1.5em;
}

.form-results-container h2, .form-results-container p {
  font-family: cassialight, Georgia, Times New Roman, Times, serif;
}


.has-success .checkbox label, .has-success .radio label {
  color: #3c763d;
}

.has-warning .checkbox label, .has-warning .radio label {
  color: #8a6d3b;
}

.has-error .checkbox label, .has-error .radio label {
  color: #a94442;
}

.form-control {
  border-radius: 0;
}

.form-group > *, .form-group > input {
  margin-right: 5px;
}

.form-group > .input-group {
  margin-right: 0;
}

.form-horizontal .form-group > * {
  margin-right: 0;
}

.input-group-addon {
  border-radius: 0;
}

.clickable {
  cursor: pointer;
}

.mt-icon-link {
  font-size: 0;
}

.mt-non-link {
  cursor: default;
}

/* hide toolbar below 1200px begin */
@media(max-width: 1200px) {
  body.plone-toolbar-left {
    padding-left: 0 !important;
  }
  #edit-zone {
    display: none;
  }
}
/* hide toolbar below 1200px end */

