.mtt-header-actions-and-status-bar {
  height: 6rem;
  padding: 0 var(--mt5-header-horizontal-padding);
  /* padding-block: 0;
  padding-left: max(env(safe-area-inset-left), var(--mt5-header-horizontal-padding));
  padding-right: max(env(safe-area-inset-right), var(--mt5-header-horizontal-padding)); */
  background-color: var(--mt-color-primary-black);
  display: flex;
  align-items: center;
  color: var(--mt-color-white);
  --mtt-hasb-desktop-search-bar-width: max(calc(29.11 * var(--mt-container-width-unit)), 31rem);
}

.mtt-mobile .mtt-header-actions-and-status-bar {
  --mtt-hasb-mobile-flex-gap: 0.76rem;
  height: 7.39rem;
  align-items: flex-end;
  gap: var(--mtt-hasb-mobile-flex-gap);
  padding-top: 0.76rem;
  padding-bottom: 0.83rem;
  flex-wrap: wrap;
}

.mtt-mobile .mtt-header-actions-and-status-bar a.button.button-edge-icon {
  min-width: 4.94rem;
  min-height: 2.22rem;
  font-size: var(--mt-text-size-small);
  margin-top: auto;
  margin-bottom: 0.1rem;
  padding: 0.399rem;
}

.mtt-hasb-divider {
  margin: 0 calc(1.25 * var(--mt-container-width-unit));
  width: 0;
  height: 0.833rem;
  border-right: 1px solid var(--mt-color-white);
  transform: translateY(-2px);
}

.mtt-hasb-initials-container {
  background: var(--mt-color-primary-red);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.55rem;
  width: 2.61rem;
  border-radius: 0.67rem;
  margin-right: 0.6rem;
  margin-left: calc(4.42 * var(--mt-container-width-unit));
  flex-shrink: 0;
}

.mtt-mobile .mtt-hasb-initials-container {
  margin-left: auto;
  margin-top: auto;
  margin-bottom: 0.1rem;
  margin-right: 0;
  height: 2.22rem;
}

.mtt-hasb-edge-status-icon {
  height: 1.444rem;
}
.mtt-mobile .mtt-hasb-edge-status-icon {
  height: 1.18rem;
}

.mtt-mobile .mtt-hasb-edge-status-icon {
  margin-left: auto;
}

.mtt-hasb-greeting {
  margin-right: calc(1.875 * var(--mt-container-width-unit));
}
.mtt-mobile .mtt-hasb-greeting {
  font-size: var(--mt-text-size-extra-small);
  line-height: var(--mt-text-size-extra-small-line-height);
}

.mtt-hasb-user-actions-container {
  position: relative;
  padding: 0.5rem 1rem;
  margin-right: -1rem;
  cursor: pointer;
  flex-shrink: 0;
}

.mtt-mobile .mtt-hasb-user-actions-container {
  padding: 0.15rem 1rem 0.25rem 1rem;
  z-index: 51;
}

.mtt-hasb-user-actions {
  position: absolute;
  display: none;
  top: 100%;
  left: 0;
  white-space: nowrap;
  padding: 1.61rem 3rem 1.55rem 3rem; 
  background: var(--mt-color-primary-black);
  z-index: 40;
}

.mtt-mobile .mtt-hasb-user-actions {
  position: absolute;
  top: 100%;
  left: unset;
  right: -6rem;
}

.mtt-hasb-user-actions a {
  color: var(--mt-color-white) !important;
  font-weight: bold;
}
.mtt-hasb-user-actions li + li{
  margin-top: 1.05rem;
}

.mtt-hasb-user-actions ul {
  padding-left: 0;
}

.mtt-hasb-user-actions-container:hover .mtt-hasb-user-actions, .mtt-hasb-user-actions.mtt-hasb-user-actions-open {
  display: block;
}

.mtt-hasb-actions-group {
  display: flex;
  align-items: center;
}

.mtt-hasb-actions-group .button + .button {
  margin-left: 1rem;
}

.mtt-hasb-logo {
  height: 3.87rem;
  margin-right: calc(1.875 * var(--mt-container-width-unit));
}

.mtt-mobile .mtt-hasb-logo {
  height: 2.59rem;
}

.mtt-hasb-menu-icon {
  height: 1.444rem;
}

.mtt-mobile .mtt-hasb-menu-icon {
  height: 1rem;
}

.mtt-hasb-menu-icon-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 1.5rem;
  margin: -1.5rem;
}

.mtt-mobile .mtt-hasb-menu-icon-container {
  padding: 0.5rem;
  margin: -0.5rem;
}

.mtt-hasb-right-side-container {
  min-width: calc(50% - var(--mtt-hasb-desktop-search-bar-width) / 2);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.mtt-mobile .mtt-hasb-right-side-container {
  gap: var(--mtt-hasb-mobile-flex-gap);
  margin-left: auto;
}

.mtt-hasb-search-and-nav-container-mobile {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
}

.mtt-hasb-search-bar {
  background-color: var(--mt-color-white);
  border-radius: var(--mt-button-border-radius);
  width: var(--mtt-hasb-desktop-search-bar-width);
  flex-shrink: 1;
  height: var(--mt-small-button-height);
  position: relative;
  padding-left: 1rem;
  padding-right: 3.5rem;
  display: flex;
  align-items: center;
  margin-left: auto;
}

@media (max-width: 1420px) {
  .mtt-header-actions-and-status-bar {
    --mtt-hasb-desktop-search-bar-width: calc(39.29 * var(--mt-container-width-unit));
  }
}

.mtt-mobile .mtt-hasb-search-bar {
  width: 100%;
  margin-right: 1.39rem;
  height: 2.44rem;
  font-size: 1rem;
}

.mtt-hasb-search-bar-icon {
  position: absolute;
  top: 50%;
  right: 1rem;
  height: 0.769rem;
  transform: translateY(-50%);
}

.mtt-hasb-search-bar-icon-clickable {
  cursor: pointer;
}

.mtt-hasb-search-close-icon {
  position: absolute;
  top: 50%;
  right: 2.769rem;
  height: 0.769rem;
  transform: translateY(-50%);
  cursor: pointer;
}

input[type="text"].mtt-hasb-search-input, input[type="text"].mtt-hasb-search-input:focus {
  width: 100%;
  border: none;
  padding: 0;
  font-size: 1rem;
  line-height: 1.4;
  letter-spacing: 0;
  color: var(--mt-color-primary-black);  
}

input[type="text"].mtt-hasb-search-input::placeholder {
  color: var(--mt-color-disabled-grey);
}
input[type="text"].mtt-hasb-search-input:placeholder-shown {
  text-overflow: ellipsis;
}

input[type="text"].mtt-hasb-search-input::-ms-clear {
  display: none;
  height: 0;
  width: 0;
}

.mtt-hasb-search-form {
  width: 100%;
}