.mtt-nav-level-container-mobile {
  position: relative;
  overflow: hidden;
  transition: min-height .2s;
}

.mtt-footer .mtt-nav-level-container-mobile {
  overflow: hidden;
}

.mtt-nav-level-container-desktop {
  width: 25%;
  position: relative;
}

.mtt-nav-level {
  width: 100%;
  padding-left: 0;
  padding-bottom: 87px;
  /* for some reason this wasn't working when I tested it on iPhone 11, Safari 18.2 */
  /* it may be that it only accounts for menu bar and not for Safari nav bar */
  /* padding-bottom: env(safe-area-inset-bottom); */
  
}

.mtt-nav-level-in-collapse {
  padding-bottom: 0;
}

.mtt-nav-levels-container {
  position: relative;
}

.mtt-nlc-back-container {
  min-height: 1.33rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #CE0E2D;
  margin-bottom: 1.9rem;
}

.mtt-nlc-bc-arrow {
  transform: rotate(180deg);
  margin-right: 1.11rem;
}

.mtt-nl-collapse-control {
  padding-top: 0.36rem;
  display: flex;
  align-items: center;
  gap: 0.53rem;
  font-size: 1rem;
  margin-left: 1.5rem;
  cursor: pointer;
}

.mtt-nl-collapse-control img.mtt-nl-cc-arrow {
  margin: 0;
  height: 0.5rem;
  transform: rotate(90deg);
  transition: transform 0.3s ease;
}
.mtt-nl-collapse-control img.mtt-nl-cc-arrow-up {
  transform: rotate(-90deg);
}

.mtt-nl-item {
  font-size: 1.11rem;
  line-height: 1.35;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 1.34rem;
  cursor: pointer;
  transition: all 0.2s;
  --mt-nl-item-space-between-items: 2.44rem;
}
.mtt-nl-item + .mtt-nl-item {
  margin-top: var(--mt-nl-item-space-between-items);
}

.mtt-mobile .mtt-nl-item {
  margin-right: 0;
}

.mtt-nl-item img {
  margin-right: 0.25rem;
  margin-left: 0.5rem;
}

/* .mtt-nl-item img { */
  /* convert black to #4b4d4a */
  /* filter: invert(29%) sepia(6%) saturate(272%) hue-rotate(56deg) brightness(93%) contrast(86%); */
/* } */

.mtt-nl-item.mtt-nl-category {
  cursor: inherit;
  padding-bottom: 0.51rem;
  border-bottom: 1px solid white;
  margin-right: 2rem;
}

.mtt-nl-item.mtt-nl-item-hidden {
  display: none;
}

.mtt-nl-item.mtt-nl-item-none-selected, .mtt-nl-item.mtt-nl-item-selected, .mtt-nl-item.mtt-nl-category, .mtt-nl-item:hover,
.mtt-nl-item.mtt-nl-item-none-selected a, .mtt-nl-item.mtt-nl-item-selected a, .mtt-nl-item.mtt-nl-category a, .mtt-nl-item:hover a  {
  font-weight: bold;
  color: var(--mt-color-white);
}

.mtt-nl-item.private, .mtt-nl-item.private a {
  color: var(--mt-color-secondary-red);
}

.mtt-nl-item-in-collapse {
  margin-left: 1.29rem;
  margin-top: 1.42rem;
  margin-bottom: 1.08rem; 
}

.mtt-nl-item-in-collapse, .mtt-nl-item-with-collapse {
  margin-right: 0;
}

.mtt-nl-item-with-collapse {
  display: block;
  cursor: default;
}

.mtt-nl-item-with-collapse + .mtt-nl-item {
  margin-top: 1.44rem;
}
.mtt-nl-item-with-collapse-expanded + .mtt-nl-item {
  margin-top: var(--mt-nl-item-space-between-items);
}

.mtt-nl-show-links-item {
  /* color: var(--mt-color-secondary-grey); */
  color: var(--mt-color-secondary-grey-lighter);
  display: flex;
  min-height: 3.994rem;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
}


.mtt-nl-show-links-item:hover  {
  color: var(--mt-color-white);
}

