body.mtt-header-mobile-nav-body-slide {
  right: var(--mtt-nav-container-mobile-width);
  transition: right 0.3s ease;
  /* transition: right 0.3s 0.075s ease; */
  transition-delay: 0;
  /* transform: translateX(calc(-1 * (var(--mtt-nav-container-mobile-width)))); */
}

.mtt-header-mobile-nav-backdrop {
  position: fixed;
  /* we make backdrop slightly wider than viewport to compensate for iOS safari rendering bug 
     that was creating a narrow sliver not covered by the backdrop on the left in portrait mode  */
  width: 101vw;
  top: 0;
  left: -1vw;
  right: 0;
  bottom: 0;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 39;
}
.mtt-header-mobile-nav-backdrop-open {
  overflow: hidden;
  background-color: #000000D9;
  visibility: visible;
  pointer-events: auto;
  opacity: 1;
}


.mtt-header-mobile-nav-container {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    /* overflow-y: scroll prevents the menu from shifting, and sometimes switching back to desktop view, 
       when position: fixed on the body removes the scrollbar */
    overflow-y: scroll;
    color: white;
    width: var(--mtt-nav-container-mobile-width);
    height: 100vh;
    background: var(--mt-color-primary-black);
    padding-block: 1.16rem;
    padding-left: 1.33rem;
    padding-right: 0.89rem;
    transform: translateX(var(--mtt-nav-container-mobile-width));
    transition: transform 0.3s ease;
    z-index: 40;
}
.mtt-header-mobile-nav-container-open {
    transform: translateX(0);
}

.mtt-hmnc-actions-container {
  display: flex;
  gap: 0.61rem;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 8.88rem;
  /* padding-right: env(safe-area-inset-right); */
}

.mtt-hmnc-search-bar {
  width: 100%;
  height: 2.444rem;
  font-size: 1rem;
  background-color: var(--mt-color-white);
  border-radius: var(--mt-button-border-radius);
  flex-shrink: 1;
  position: relative;
  padding-left: 1rem;
  padding-right: 3.5rem;
  display: flex;
  align-items: center;
}

.mtt-hmnc-button.button.button-small {
  min-height: 2.444rem;
  padding-block: 0.67rem;
  padding-inline: 0.86rem;
  flex-shrink: 0;
} 

.mtt-hmnc-close-x {
  position: absolute;
  color: white;
  font-weight: bold;
  font-size: 2.06rem;
  line-height: 1.35;
  right: 2.94rem;
  top: calc(2.33rem + var(--mtt-hmnc-close-x-scroll-offset-top));
  z-index: 50;
}
.mtt-hmnc-close-x:hover {
  color: white;
}


.mtt-hmnc-status-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 1.6rem;
  /* padding-right: env(safe-area-inset-right); */
}

.mtt-hmnc-sc-account-code {
  font-size: 0.89rem;
  line-height: 1.29;
  padding-right: 0.5rem;
}

.mtt-hmnc-sc-edge-status-icon {
  height: 1.6rem;
}


/* .mtt-nav-container-mobile {
  position: fixed;
  right: -100%;
  top: 0;
  bottom: 0;
  overflow-y: scroll;
  width: var(--mtt-nav-container-mobile-width);
  height: 100vh;
  padding-left: 1.61rem;
  padding-right: 1.29rem;
  background: var(--mt-color-primary-black);
  color: var(--mt-color-secondary-grey-lighter);
  transition: right 0.3s ease;
  z-index: 40;
} */
.mtt-header-nav-bar {
  display: flex;
  padding: 0 var(--mt5-header-horizontal-padding);
  justify-content: center;
  align-items: center;
  height: 3.22rem;
  position: relative;
  background-color: var(--mt-color-primary-red);
}

/* .mtt-mobile .mtt-header-nav-bar {
  padding-right: 4.61vw;
  height: 6.67rem;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
} */

.mtt-mobile .mtt-header-nav-bar {
  flex-wrap: wrap;
}

.mtt-hnb-nav-items {
  display: flex;
  justify-content: space-between;
  color: white;
  font-size: 1.11rem;
  font-weight: bold;
  position: relative;
  padding-right: 1.89rem;
}


.mtt-hnb-nav-item {
  padding-inline: 1.44rem;
  z-index: 30;
  position: relative;
  cursor: pointer;
}
.mtt-hnb-nav-item-is-selected {
  color: black;
}
.mtt-hnb-nav-item:before {
  opacity: 0;
  content: " ";
  width: 100%;
  top: -0.88rem;
  left: 0;
  position: absolute;
  border-radius: 0.56rem 0.56rem 0 0;
  z-index: -1;
  height: 4rem;
  background-color: white;
}
.mtt-hnb-nav-item-is-selected:before {
  transition: opacity 0.3s ease;
  opacity: 1;
}

.mtt-hnb-desktop-nav {
  position: absolute;
  /* top: calc(100% + 1.28rem); */
  top: calc(100% + 0.45rem);
  left: 0;
  right: 0;
  width: calc(100% - 0.9rem);
  max-width: 95.88rem;
  margin-inline: auto;
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 0 0 0.56rem 0.56rem;
  padding-top: 1.67rem;
  padding-bottom: 0.22rem;
  padding-right: 3.19rem;
  padding-left: 2rem;
  color: black;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap:3.6rem;
}

@media (min-width: 88rem) {
  .mtt-hnb-desktop-nav {
    padding-left: 3.19rem;
    gap: min(6.39rem, calc(5.68 * var(--mt-container-width-unit)));
  }
}



.mtt-hnb-dn-second-level-item {
  padding-bottom: 0.444rem;
}

/* .mtt-hnb-second-level-item-admin-link a {
  color: var(--mt-color-secondary-grey-lighter);
} */
/* Remove this if we decide to go with grey color for admin link just above */
.mtt-hnb-second-level-item-admin-link a, .mtt-hnb-second-level-item-admin-link a:hover {
  color: var(--mtt-private-item-color) !important;
}

.mtt-hnb-dn-second-level-item h3 {
  font-weight: bold;
  text-transform: none;
  font-size: 1.11rem;
  line-height: 1.2;
  color: #CE0E2D;
}
.mtt-hnb-dn-second-level-item h3 a {
  color: #CE0E2D;
}
.mtt-hnb-dn-second-level-item-has-children h3 {
  border-bottom: 1px solid #707070;
  margin-bottom: 0.528rem;
  line-height: 1.35;
}
.mtt-hnb-second-level-item-admin-link h3 {
  border-bottom: none;
}
.mtt-hnb-dn-second-level-item-has-children, .mtt-hnb-dn-second-level-item:last-of-type  {
  padding-bottom: 2.7rem;
}

.mtt-hnb-dn-third-level-items {
  column-count: 2;
  column-gap: 2.22rem;
  font-weight: normal;
}
.mtt-hnb-dn-third-level-items-wide {
  column-count: 2;
  column-fill: balance;
  column-gap: 1.68rem;
}

.mtt-hnb-dn-tli-item {
  font-size: 0.89rem;
  line-height: 1.375;
  break-inside: avoid;
}


.mtt-hnb-dn-tli-item a:hover, .mtt-hnb-dn-tli-item a:focus, .mtt-hnb-dn-tli-item a:active {
  font-weight: normal;
}
.mtt-hnb-dn-tli-item-spacer {
  height: 0.444rem;
}

.mtt-hnb-nav-item.private, .mtt-hnb-dn-second-level-item.private a, .mtt-hnb-dn-tli-item.private a{
  color: var(--mtt-private-item-color);
}

