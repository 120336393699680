.mtt-footer {
  padding: 2.37rem var(--mt-footer-horizontal-padding) 0 var(--mt-footer-horizontal-padding);
}
.mtt-footer.mtt-mobile {
  padding: 1.78rem 1.78rem 0 1.78rem;
}
.mtt-footer-contact {
  padding-top: 1.36rem;
  font-weight: bold;
}
.mtt-footer-contact a, .mtt-footer-contact a:hover {
  color: white;
}

.mtt-footer-follow-container img {
  max-height: 1.8rem;
  max-width: auto;
  margin-top: 0.8rem;
}
.mtt-mobile .mtt-footer-follow-container img {
  max-height: 1.65rem;
}
.mtt-footer-follow-container a + a {
  margin-left: 1rem;
}
.mtt-mobile .mtt-footer-follow-container a + a {
  margin-left: 1rem;
}
.mtt-ffc-link-smaller img {
  max-height: 1.5rem;
  vertical-align: top;
  transform: translateY(0.15rem);
}
.mtt-mobile .mtt-ffc-link-smaller img {
  max-height: 1.375rem;
  transform: translateY(0.1375rem);
}

.mtt-footer-legal {
  text-align: center;
  padding: 3.67rem 0;
  font-weight: bold;
  /* color: var(--mt-color-secondary-grey); */
  color: var(--mt-color-secondary-grey-lighter);
}

.mtt-mobile .mtt-footer-legal {
  padding: 3.16rem 0 2.3rem 0;
}

.mtt-footer-logo-container {
  padding: 0 var(--mt-footer-horizontal-padding-inner) 2.67rem var(--mt-footer-horizontal-padding-inner);
}
.mtt-mobile .mtt-footer-logo-container {
  padding: 0 0 1.78rem 0;
  /* border-bottom: 1px solid var(--mt-color-secondary-grey); */
  border-bottom: 1px solid var(--mt-color-secondary-grey-lighter);
}
.mtt-footer-logo-container img {
  height: 3.87rem;
}
.mtt-mobile .mtt-footer-logo-container img {
  height: 1.69rem;
}

.mtt-footer-more-info-container {
  padding: 2.88rem var(--mt-footer-horizontal-padding-inner) 0 var(--mt-footer-horizontal-padding-inner);
  /* border-top: 1px solid var(--mt-color-secondary-grey); */
  border-top: 1px solid var(--mt-color-secondary-grey-lighter);
}

.mtt-mobile .mtt-footer-more-info-container {
  padding: 1.94rem 0 0 0;
}

.mtt-footer-nav-container-mobile {
  padding: 1rem 0 2.44rem 0;
  overflow: hidden;
}

.mtt-footer-nav-desktop {
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* this hides the left border on categories that have wrapped to new row */
.mtt-footer-nav-desktop:after {
  content: '';
  position: absolute;
  border-left: 1px solid black;
  z-index: 1;
  left: 0;
  top: 0;
  height: 100%;
  width: 0;
}

.mtt-fnd-category-container {
  padding: 1.25rem var(--mt-footer-horizontal-padding-inner) var(--mt-footer-horizontal-padding-inner) var(--mt-footer-horizontal-padding-inner);
  border-bottom: 1px solid var(--mt-color-secondary-grey);
  margin-bottom: -1px;
  flex-grow: 1;
}
.mtt-fnd-category-container + .mtt-fnd-category-container {
  border-left: 1px solid var(--mt-color-secondary-grey);
}

.mtt-fnd-category-title, .mtt-fnd-desktop-item {
  display: flex;
  align-items: center;
  min-height: 2.78rem;
}
.mtt-fnd-category-title.private, .mtt-fnd-desktop-item.private, .mtt-fnd-desktop-item.private a, .mtt-fnd-desktop-item.private a:hover {
  /* color: var(--mt-color-secondary-red); */
  color: var(--mtt-private-item-color);
}

.mtt-fnd-desktop-item, .mtt-fnd-desktop-item a, .mtt-fnd-desktop-item a:hover {
  cursor: pointer;
  font-weight: bold;
  color: var(--mt-color-white);
}

.mtt-fnd-desktop-item-hidden {
  display: none;
}

.mtt-fnd-level {
  padding-bottom: 0.75rem;
}

.mtt-fnd-level-2 .mtt-fnd-desktop-item {
  opacity: 0.7;
}
.mtt-fnd-level-3 .mtt-fnd-desktop-item {
  opacity: 0.4;
}

.mtt-footer-split-info-container {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 540px) {
  .mtt-footer-split-info-container {
    display: block;
  }
  .mtt-footer-follow-container {
    margin-top: 1.8rem;
  }
}

